import "./AddWebLink.css";

import React, { useState } from "react";
import { createWebLink, replaceWebLink } from "Config/API/update";

import { toast } from "react-toastify";


const AddWebLink = ({
  hide,
  classify,
  getPortfolio,
  parent_unique_id,
  folderKey,
  replaceWeblinkData,
  oldLink,
  oldLinkName,
  uniqueId,
}) => {
  const token = localStorage.getItem("token");
  const [linkName, setLinkName] = useState(oldLinkName || "");
  const [link, setLink] = useState(oldLink || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateURL = (url) => {
      const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/i;
      return regex.test(url);
    };


    if (!validateURL(link)) {

      toast("Please enter a valid URL.", {
        type: "error",
      });
      return;
    }

    if (replaceWeblinkData) {
      const response = replaceWebLink(token, linkName, uniqueId, link);
      response.then((res) => {
        if (res.status) {
          toast(res.message, {
            type: "success",
          });
          hide(false);
          getPortfolio();
        } else
          toast(res.message, {
            type: "error",
          });
      });
    } else {
      const response = createWebLink(
        token,
        linkName,
        classify,
        parent_unique_id,
        folderKey,
        link
      );
      response.then((res) => {
        if (res.status) {
          toast(res.message, {
            type: "success",
          });
          hide(false);
          getPortfolio();
        } else
          toast(res.message, {
            type: "error",
          });
      });
    }
  };

  return (
    <div className="add-web-link-parent">
      <div className="add-web-link-main">
        <form
          className="add-web-link-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="add-web-link-heading">
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="add-web-link-wrapper">
            <div className="add-web-link-fields">
              <label>{replaceWeblinkData ? "Replace" : "Add"} Web Link</label>
            </div>

            <div className="add-web-link-fields">
              <input
                type="text"
                required
                maxLength={40}
                placeholder="Add Link Name"
                value={linkName}
                onChange={(e) => setLinkName(e.target.value.trimStart())}
              />
            </div>
            <div className="add-web-link-fields">

              <input
                type="text"
                required
                placeholder="Paste URL here"
                value={link}
                onChange={(e) => setLink(e.target.value.trim())}
                style={{
                  borderColor: link && !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/i.test(link) ? 'red' : 'initial',
                  outline: 'none',
                }}
                pattern="^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$"
                title="Please enter a valid URL starting with http:// or https://"
              />

            </div>

            <div className="add-web-link-fields">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddWebLink;
