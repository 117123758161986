import "./Directory.css";
import React, { useEffect, useState } from "react";
import {
  currentFolderAutomationAction,
  currentVisitOrgAction,
  globalOptionButtonAction,
} from "Redux/action/variable.action";
import {
  downloadFolder,
  renameFolderName,
  softDelete,
} from "Config/API/action";
import { folderData, getInternalFolder, getZip } from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
  storeFolderDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import IFramePopup from "Components/Popup/IFramePopup/IFramePopup";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import ManageFolderPopup from "Components/Popup/ManageFolderPopup/ManageFolderPopup";
import RenamePopup from "Components/Popup/RenamePopup/RenamePopup";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import ShareFolderLinkExpiryDayPopup from "Components/Popup/ShareFolderLinkExpiryDayPopup/ShareFolderLinkExpiryDayPopup";
import ShareFolderLinkPopup from "Components/Popup/ShareFolderLinkPopup/ShareFolderLinkPopup";
import bFolder from "Media/icons/Folder-Close.png";
import cadIcon from "Media/icons/cad.png";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import options from "Media/icons/feather-more-vertical.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import pptDocument from "Media/icons/pptx.png";
import unknownFile from "Media/icons/unknownFile.png";
import videoFile from "Media/icons/videoFile.png";
import webLinkImage from "Media/icons/webLinkImage.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useRef } from "react";
import { toast } from "react-toastify";

const DropDownMenu = ({
  id,
  file,
  permission,
  setRenamePopup,
  setRenameType,
  setLoader,
  resetLoader,
  usersAllPermissions,
  setConfirmPopup,
  setSelectedFolderId,
  setShowMoveFolderPopup,
  setShowFolderLinkExpiryPopup,
  setCurrentName,
  setShowIFramePopup,
  setSelectedWeblinkPath,
}) => {
  const internalFolder = useSelector(
    (state) => state.internalFolderDataReducer
  );
  const token = localStorage.getItem("token");
  const clickRef = useRef(null);

  let folderScope = "";

  useOutsideAlerter(clickRef);

  // condition when accessing internal folders
  if (internalFolder !== "") {
    folderScope = "internal";
  }

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        ref.current.classList.add("hide-dropdown");
      } else {
        if (ref.current !== null) {
          ref.current.classList.remove("hide-dropdown");
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  const DownloadFiles = (files) => {
    fetch(files)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = file?.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast("Downloading completed", {
          type: "success",
        });
      })
      .catch(() => {
        toast("Downloading failed", {
          type: "error",
        });
      });
  };
  return (
    <>
      <div className="dropdown-menu" ref={clickRef}>
        <ul>
          {permission?.toString() === "all" ||
          permission?.toString() === "write" ||
          permission?.toString() === "read" ? (
            file?.type?.toString() === "file" ? (
              <li onClick={() => DownloadFiles(file?.path)}>Download</li>
            ) : (
              file?.type === "folder" && (
                <li
                  onClick={() => {
                    setLoader();
                    const download_status = downloadFolder(
                      token,
                      id,
                      folderScope
                    );
                    download_status.then((res) => {
                      if (res?.status) {
                        const zip_status = getZip(
                          token,
                          id,
                          folderScope,
                          "",
                          ""
                        );
                        zip_status.then((response) => {
                          if (response?.status) {
                            const linkSource = `data:application/octet-stream;base64,${response?.results}`;
                            const downloadLink = document.createElement("a");
                            const fileName = file?.name + ".zip";
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            resetLoader();
                            toast(response?.message, {
                              type: "success",
                            });
                          } else {
                            toast(response?.message, { type: "error" });
                            resetLoader();
                          }
                        });
                      } else {
                        toast(res?.message, {
                          type: "error",
                        });
                        resetLoader();
                      }
                    });
                  }}
                >
                  Download
                </li>
              )
            )
          ) : null}

          {/* rename & delete is only for admin */}
          {permission?.toString() === "all" ||
          permission?.toString() === "write" ||
          usersAllPermissions?.permission_write_rights?.toString() ===
            "write" ||
          usersAllPermissions?.permission_file_manager?.toString() ===
            "write" ? (
            <>
              {setRenamePopup !== undefined ? (
                <>
                  {file?.type?.toString() !== "folder" ||
                  internalFolder !== "" ? (
                    <li
                      onClick={() => {
                        setRenamePopup(true);
                        setRenameType("rename");
                        setCurrentName(file?.name);
                      }}
                    >
                      Rename
                    </li>
                  ) : (
                    usersAllPermissions?.permission_file_manager?.toString() ===
                      "write" && (
                      <>
                        <li
                          onClick={() => {
                            setRenamePopup(true);
                            setRenameType("rename");
                            setCurrentName(file?.name);
                          }}
                        >
                          Rename
                        </li>
                        <li
                          onClick={() => {
                            setRenamePopup(true);
                            setRenameType("second");
                          }}
                        >
                          Add Second Name
                        </li>
                      </>
                    )
                  )}
                </>
              ) : null}
            </>
          ) : null}

          {permission?.toString() === "all" ||
          (permission?.toString() === "write" &&
            usersAllPermissions?.permission_file_manager?.toString() ===
              "write") ? (
            <>
              <li
                onClick={() => {
                  setSelectedFolderId(id);
                  setConfirmPopup(true);
                }}
              >
                Archive
              </li>
              <li
                onClick={() => {
                  setSelectedFolderId(id);
                  setShowMoveFolderPopup(true);
                }}
              >
                Move
              </li>
              {file?.type?.toString() === "folder" && (
                <li
                  onClick={() => {
                    setSelectedFolderId(id);
                    setShowFolderLinkExpiryPopup(true);
                  }}
                >
                  Share Link
                </li>
              )}
            </>
          ) : null}

          {permission?.toString() === "all" ||
          permission?.toString() === "write" ||
          permission?.toString() === "read"
            ? file?.type?.toString() !== "folder" &&
              (file?.type === "file" ? (
                <li
                  onClick={() => {
                    localStorage.setItem("filePath", file?.path);
                    localStorage.setItem(
                      "fileType",
                      file?.key?.split(".").pop()?.toLowerCase()
                    );
                  }}
                >
                  {console.log("file path----", file, file?.path)}
                  <Link
                    className="open-document-link"
                    to={`file-viewer`}
                    target={"_blank"}
                  >
                    Open
                  </Link>
                </li>
              ) : (
                <li
                  onClick={() => {
                    setSelectedWeblinkPath(file?.path);
                    setShowIFramePopup(true);
                  }}
                >
                  Open
                </li>
              ))
            : null}
        </ul>
      </div>
    </>
  );
};

const Directory = ({ files, usersAllPermissions }) => {
  const dispatch = useDispatch();
  const [renamePopup, setRenamePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [showMoveFolderPopup, setShowMoveFolderPopup] = useState(false);
  const [showIFramePopup, setShowIFramePopup] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [selectedWeblinkPath, setSelectedWeblinkPath] = useState("");
  const [showFolderLinkPopup, setShowFolderLinkPopup] = useState(false);
  const [showFolderLinkExpiryPopup, setShowFolderLinkExpiryPopup] =
    useState(false);
  const [folderLink, setFolderLink] = useState("");
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  // renameType specific either we updating folder name or second name
  const [renameType, setRenameType] = useState("");
  const token = localStorage.getItem("token");
  const isOptions = useSelector((state) => state.globalOptionButtonReducer);
  const addFolderData = useSelector((state) => state.selectedFolderDataReducer);
  const allConnectedFolders = useSelector(
    (state) => state.getFolderDataReducer
  );
  const internalFolder = useSelector(
    (state) => state.internalFolderDataReducer
  );
  // this state is for automated folder opening
  const folderPathFromUrl = useSelector(
    (state) => state.currentFolderAutomationReducer
  );
  // eslint-disable-next-line no-unused-vars
  const [automation_id, setAutomation_id] = useState(
    folderPathFromUrl ? folderPathFromUrl : ""
  );
  const [currentName, setCurrentName] = useState("");
  let folderScope = "";
  // condition when accessing internal folders
  if (internalFolder !== "") {
    folderScope = "internal";
  }

  if (
    files === undefined ||
    files === null ||
    files === "" ||
    files?.length === 0
  ) {
    files = {};
  }

  const automationFunc = (auto_id) => {
    if (auto_id?.includes("/")) {
      for (let i in internalFolder) {
        if (auto_id?.includes(internalFolder[i]?.unique_id)) {
          // if file return on hitting file
          if (internalFolder[i]?.type?.toString() === "file") {
            dispatch(currentFolderAutomationAction(""));
            return;
          } else {
            // folder end point
            let end_point = "";
            end_point = auto_id.split("/")[auto_id.split("/").length - 1];
            if (end_point?.toString() === "") {
              end_point = auto_id.split("/")[auto_id.split("/").length - 2];
            }
            if (end_point?.toString() === files?.unique_id?.toString()) {
              dispatch(currentFolderAutomationAction(""));
            }
          }

          // dispatching internal items
          dispatch(selectedFolderDataAction(internalFolder[i]));
          dispatch(internalFolderDataAction(internalFolder[i]?.items));
        }
      }
    }
  };

  // internal folder automation
  useEffect(() => {
    if (
      internalFolder !== "" &&
      automation_id !== "" &&
      automation_id !== undefined &&
      automation_id[0] !== "/"
    ) {
      automationFunc(automation_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRenameOrArchiveFolderAndFileInternallySuccess = (
    array,
    selectedFolderUniqueId
  ) => {
    // eslint-disable-next-line array-callback-return
    array.map((item) => {
      if (item?.unique_id === selectedFolderUniqueId) {
        dispatch(selectedFolderDataAction(item));
        dispatch(internalFolderDataAction(item?.items));
      } else
        onRenameOrArchiveFolderAndFileInternallySuccess(
          item?.items,
          selectedFolderUniqueId
        );
    });
  };

  const onRenameOrArchiveFolderAndFileSharedSuccess = (
    array,
    selectedFolderUniqueId
  ) => {
    // eslint-disable-next-line array-callback-return
    array.map((item) => {
      if (item?.unique_id === selectedFolderUniqueId) {
        dispatch(selectedFolderDataAction(item));
        dispatch(selectedFileDataAction(item?.items));
      } else
        onRenameOrArchiveFolderAndFileSharedSuccess(
          item?.items,
          selectedFolderUniqueId
        );
    });
  };

  const renameFolder = (data) => {
    setLoader();
    toast("Updating, Please Wait...", { type: "info" });
    const rename_status = renameFolderName(
      token,
      files?.unique_id,
      data,
      renameType,
      folderScope
    );
    rename_status
      .then((response) => {
        if (response?.status) {
          if (internalFolder !== "") {
            const internalFolderStatus = getInternalFolder(token);
            internalFolderStatus.then((res) => {
              resetLoader();
              toast(response?.message, {
                type: "success",
              });
              onRenameOrArchiveFolderAndFileInternallySuccess(
                res?.result,
                addFolderData?.unique_id
              );
              dispatch(selectedFolderRootDataAction(res?.result));
            });
          } else {
            const folderDataApiResponse = folderData(token);
            folderDataApiResponse.then((res) => {
              resetLoader();
              toast(response?.message, {
                type: "success",
              });
              onRenameOrArchiveFolderAndFileSharedSuccess(
                res?.result,
                addFolderData?.unique_id
              );
              dispatch(storeFolderDataAction(res?.result));
              // eslint-disable-next-line array-callback-return
              res?.result?.map((item) => {
                if (item?.conn_id === addFolderData?.conn_id)
                  dispatch(selectedFolderRootDataAction(item));
              });
            });
          }
        } else {
          resetLoader();
          toast(response?.message, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  const archivefileAndFolder = (id) => {
    setLoader();
    toast("Archiving, Please Wait...", { type: "info" });
    const delete_status = softDelete(token, id, folderScope);
    delete_status
      .then((response) => {
        if (response?.status) {
          if (internalFolder === "") {
            const folderDataApiResponse = folderData(token);
            folderDataApiResponse.then((res) => {
              resetLoader();
              toast(response?.message, {
                type: "success",
              });
              onRenameOrArchiveFolderAndFileSharedSuccess(
                res?.result,
                addFolderData?.unique_id
              );
              dispatch(storeFolderDataAction(res?.result));
              // eslint-disable-next-line array-callback-return
              res?.result?.map((item) => {
                if (item?.conn_id === addFolderData?.conn_id)
                  dispatch(selectedFolderRootDataAction(item));
              });
            });
          } else {
            const internalFolderStatus = getInternalFolder(token);
            internalFolderStatus.then((res) => {
              resetLoader();
              toast(response?.message, {
                type: "success",
              });
              onRenameOrArchiveFolderAndFileInternallySuccess(
                res?.result,
                addFolderData?.unique_id
              );
              dispatch(selectedFolderRootDataAction(res?.result));
            });
          }
        } else {
          resetLoader();
          toast(response?.message, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  if (!files) return;

  if (files?.type?.toString() !== "folder") {
    let icon = "";
    if (files?.type === "weblink") {
      icon = webLinkImage;
    } else {
      const fileType = files?.name?.split(".").pop()?.toLowerCase();
      switch (fileType) {
        case "png":
        case "jpg":
        case "jpeg":
          icon = imageIcon;
          break;

        case "pdf":
          icon = pdfIcon;
          break;

        case "docx":
        case "doc":
          icon = officeIcon;
          break;

        case "xlsx":
        case "xls":
        case "csv":
          icon = excelIcon;
          break;
        case "ppt":
        case "pptx":
          icon = pptDocument;
          break;
        case "mp4":
        case "mkv":
        case "webm":
          icon = videoFile;
          break;
        case "stp":
        case "step":
        case "dxf":
        case "stl":
          icon = cadIcon;
          break;
        default:
          icon = unknownFile;
      }
    }

    return (
      <>
        <div
          id={files?.unique_id}
          className="directory-file-container"
          onClick={() => {
            dispatch(globalOptionButtonAction(files?.unique_id));
            if (isOptions?.toString() === files?.unique_id?.toString()) {
              dispatch(globalOptionButtonAction(""));
            }
          }}
        >
          <IconButton className="directory-file-options">
            <img src={options} alt="Menu options" />
            {isOptions?.toString() === files?.unique_id?.toString() ? (
              <DropDownMenu
                id={files?.unique_id}
                file={files}
                permission={files?.permission}
                setRenamePopup={setRenamePopup}
                setRenameType={setRenameType}
                usersAllPermissions={usersAllPermissions}
                setConfirmPopup={setConfirmPopup}
                setSelectedFolderId={setSelectedFolderId}
                setShowMoveFolderPopup={setShowMoveFolderPopup}
                setShowFolderLinkExpiryPopup={setShowFolderLinkExpiryPopup}
                setCurrentName={setCurrentName}
                setLoader={setLoader}
                resetLoader={resetLoader}
                setShowIFramePopup={setShowIFramePopup}
                setSelectedWeblinkPath={setSelectedWeblinkPath}
              />
            ) : null}
          </IconButton>

          <div className="directory-file-icon">
            {icon !== "" ? (
              <img src={icon} alt="File Icon" />
            ) : (
              <HelpCenterIcon />
            )}
          </div>
          <div className="directory-file-text">
            {files?.name ? files?.name : null}
          </div>
        </div>

        {renamePopup ? (
          <RenamePopup
            hide={setRenamePopup}
            verify={renameFolder}
            type={renameType}
            currentName={currentName}
          />
        ) : null}
        {loader}
        {confirmPopup && (
          <RenderConfirm
            message={` archive it?`}
            data={selectedFolderId}
            hide={setConfirmPopup}
            status={archivefileAndFolder}
          />
        )}
        {showMoveFolderPopup && (
          <ManageFolderPopup
            hide={setShowMoveFolderPopup}
            selectedFolderId={selectedFolderId}
            token={token}
          />
        )}
        {showIFramePopup && (
          <IFramePopup
            hide={setShowIFramePopup}
            filePath={selectedWeblinkPath}
          />
        )}
      </>
    );
  }

  return (
    <>
      {Object.keys(files).length?.toString() !== "0" ? (
        <div id={files?.unique_id} className="directory-folder-single">
          {files?.location ? null : (
            <IconButton
              className="directory-folder-options"
              onClick={() => {
                dispatch(globalOptionButtonAction(files?.unique_id));
                if (isOptions?.toString() === files?.unique_id?.toString()) {
                  dispatch(globalOptionButtonAction(""));
                }
              }}
            >
              <img src={options} alt="Menu options" />
              {isOptions?.toString() === files?.unique_id?.toString() ? (
                <DropDownMenu
                  id={files?.unique_id}
                  file={files}
                  permission={files?.permission}
                  setRenamePopup={setRenamePopup}
                  setRenameType={setRenameType}
                  setLoader={setLoader}
                  resetLoader={resetLoader}
                  usersAllPermissions={usersAllPermissions}
                  setConfirmPopup={setConfirmPopup}
                  setSelectedFolderId={setSelectedFolderId}
                  setShowMoveFolderPopup={setShowMoveFolderPopup}
                  setShowFolderLinkExpiryPopup={setShowFolderLinkExpiryPopup}
                  setCurrentName={setCurrentName}
                />
              ) : null}
            </IconButton>
          )}

          <div
            onClick={() => {
              // condition for internal folder
              if (internalFolder !== "") {
                dispatch(selectedFolderDataAction(files));
                dispatch(internalFolderDataAction(files?.items));
              } else {
                // for visit page button
                if (files?.location?.toString() === "root") {
                  dispatch(currentVisitOrgAction(files));
                }
                dispatch(selectedFolderDataAction(files));
                dispatch(selectedFileDataAction(files?.items));
                // eslint-disable-next-line array-callback-return
                allConnectedFolders.map((item) => {
                  if (item?.conn_id === files?.conn_id)
                    dispatch(selectedFolderRootDataAction(item));
                });
              }
            }}
          >
            <img src={bFolder} alt="Folder" />
            <div className="directory-folder-single-aliasname">
              <span>{files.name?.length > 10 ? files.name.slice(0, 10) + '...' : files.name}</span>
              <span>
                {!files?.location && files?.alias_name
                  ? files?.alias_name
                  : null}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <p>This folder is empty.</p>
      )}

      {renamePopup ? (
        <RenamePopup
          hide={setRenamePopup}
          verify={renameFolder}
          type={renameType}
          currentName={currentName}
        />
      ) : null}
      {loader}
      {confirmPopup && (
        <RenderConfirm
          message={` archive it?`}
          data={selectedFolderId}
          hide={setConfirmPopup}
          status={archivefileAndFolder}
        />
      )}
      {showMoveFolderPopup && (
        <ManageFolderPopup
          hide={setShowMoveFolderPopup}
          selectedFolderId={selectedFolderId}
          token={token}
        />
      )}
      {showFolderLinkPopup && (
        <ShareFolderLinkPopup
          hide={setShowFolderLinkPopup}
          folderLink={folderLink}
        />
      )}
      {showFolderLinkExpiryPopup && (
        <ShareFolderLinkExpiryDayPopup
          token={token}
          folderScope={folderScope}
          setLoader={setLoader}
          resetLoader={resetLoader}
          hide={setShowFolderLinkExpiryPopup}
          folderId={selectedFolderId}
          setFolderLink={setFolderLink}
          setShowFolderLinkPopup={setShowFolderLinkPopup}
        />
      )}
    </>
  );
};

export default Directory;
