import "./OrganisationInfoPopup.css";

import { InputAdornment, TextField } from "@mui/material";

import ApartmentIcon from "@mui/icons-material/Apartment";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import TagIcon from "@mui/icons-material/Tag";

export default function OrganisationInfoPopup({ data, setOrgPopupInfo }) {
  return (
    <div className="organisation-info-popup-wrapper">
      <div className="organisation-info-popup-main">
        <div className="organisation-info-popup-content">
          <div className="org-name org-flex">
            <div className="organisation-info-popup-flex">
              <h4>Organization Details</h4>
              <button
                onClick={() => {
                  setOrgPopupInfo({ data: {}, hide: false });
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <TextField
              value={data?.organization_name}
              label="Organization name"
              variant="outlined"
              type="text"
              style={{ marginTop: "unset" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <ApartmentIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.organization_website}
              label="Organization website"
              variant="outlined"
              type="text"
              style={{ marginTop: "20px" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.organization_phone_number}
              label="Organization phone"
              variant="outlined"
              type="number"
              style={{ marginTop: "20px" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
            />
            <h4>Admin Details</h4>
            <TextField
              value={data?.username}
              label="Admin name"
              variant="outlined"
              type="text"
              style={{ marginTop: "unset" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.email}
              label="Admin email"
              variant="outlined"
              type="email"
              style={{ marginTop: "20px", marginBottom: "unset" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.phone}
              label="Admin mobile number"
              variant="outlined"
              type="number"
              style={{ marginTop: "20px" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.office_phone}
              label="Admin office number"
              variant="outlined"
              type="number"
              style={{ marginTop: "20px" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={data?.extension}
              label="Extension"
              variant="outlined"
              type="number"
              style={{ marginTop: "20px" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <TagIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
