import "./EditOrganization.css";

import { Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import RenderTimeZone from "Components/TimeZone/Container/RenderTimeZone";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import formValidators from "Config/Validators/formValidators";
import { getGeneralInfo } from "Config/API/get";
import { renderGeneralInfo } from "Redux/action/render.action";
import { sendImageAPI } from "Config/API/action";
import { toast } from "react-toastify";
import { updateOrganization } from "Config/API/update";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";

const EditOrganization = ({ popup, hide }) => {
  const isStorageLimitReached = useIsStorageLimitReached();
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const token = localStorage.getItem("token");
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [orgName, setOrgName] = useState(general_info?.organization_name || "");
  const [orgUrl, setOrgUrl] = useState(
    general_info?.organization_website || ""
  );
  const [orgPhone, setOrgPhone] = useState(
    general_info?.organization_phone_number || ""
  );
  const [orgLogo, setOrgLogo] = useState(
    general_info?.logo ? general_info.logo : ""
  );
  const [streetAddress, setStreetAddress] = useState(
    general_info?.street || ""
  );
  const [orgCity, setOrgCity] = useState(general_info?.city || "");
  const [orgState, setOrgState] = useState(general_info?.state || "");
  const [orgCountry, setOrgCountry] = useState(general_info?.country || "");
  const [orgZipCode, setOrgZipCode] = useState(general_info?.zipcode || "");
  const [timeZone, setTimeZone] = useState(general_info?.time_zone || "");

  const [country_code, setCountry_code] = useState(
    general_info?.country_code || ""
  );

  const [corresponding_zone, setCorresponding_zone] = useState(
    general_info?.corresponding_zone || ""
  );

  const dispatch = useDispatch();
  const [loader, setLoading, resetLoading] = useFullPageLoader();

  // validators
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const date = new Date();
  const getTimezone = (country, code) => {
    let strTime = date.toLocaleString("en-US", {
      timeZone: `${country}`,
    });
    setTimeZone(strTime);
    setCountry_code(code);
    setCorresponding_zone(country);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(formValidators(orgPhone));
    setIsSubmit(true);
  };

  // we used useEffect so that we get updatedState
  useEffect(() => {
    if (Object.keys(formErrors).length?.toString() === "0" && isSubmit) {
      const address = `${streetAddress}, ${orgCity}, ${orgState}, ${orgCountry}, ${orgZipCode}`;
      const updateOrg = updateOrganization(
        orgName,
        orgUrl,
        orgPhone,
        orgLogo,
        timeZone,
        address,
        streetAddress,
        orgCity,
        orgState,
        orgCountry,
        orgZipCode,
        token,
        country_code,
        corresponding_zone
      );
      updateOrg
        .then((res) => {
          if (res?.status) {
            toast(res?.message, {
              type: "success",
            });
            const received_info = getGeneralInfo(token);
            received_info.then((res) => {
              if (res?.status) {
                dispatch(renderGeneralInfo(res?.org_info));
              }
            });
            // close tab when successfull
            setTimeout(() => {
              hide(false);
            }, 1000);
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        })
        .catch(() =>
          toast("Failed! Try again later", {
            type: "error",
          })
        );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return popup ? (
    <>
      <div className=" edit-organization-container">
        <div>
          <div className=" edit-organization-top-wrapper">
            <div className=" edit-organization-head">
              Edit Organization Details
            </div>
            <div className=" edit-organization-close">
              <IconButton
                onClick={() => {
                  hide(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className=" edit-organization-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className=" edit-organization-name">
                <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 50 }}
                  label="Update Organization Name"
                  variant="outlined"
                  value={orgName}
                  type="text"
                  onChange={(e) => {
                    setOrgName(e.target.value);
                  }}
                />
              </div>
              <div className=" edit-organization-website">
                <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 60 }}
                  label="Update Organization URL"
                  variant="outlined"
                  value={orgUrl}
                  type="text"
                  style={{ marginTop: "20px" }}
                  onChange={(e) => {
                    if (e.target.value.includes("https://")) {
                      setOrgUrl(e.target.value);
                    } else {
                      setOrgUrl("https://" + e.target.value);
                    }
                  }}
                />
              </div>
              <div className=" edit-organization-phone">
                <TextField
                  fullWidth
                  label="Update Organization Phone"
                  variant="outlined"
                
                  value={orgPhone}
                  type="number"
                  style={{ marginTop: "20px" }}
                  inputProps={{
                    max: 99999999999999, // Allows up to 14 digits
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 14) {
                      setOrgPhone(value);
                    }
                  }}
                />
                {formErrors.phone ? (
                  <p className="error">{formErrors.phone}</p>
                ) : null}
              </div>
              <div
                className="edit-organization-head"
                style={{ marginTop: "20px" }}
              >
                Update Organization Address
              </div>
              <div className="edit-organization-address">
                <TextField
                  fullWidth
                  label="Street"
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  value={streetAddress}
                  type="text"
                  style={{ width: "58%" }}
                  onChange={(e) => {
                    setStreetAddress(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  label="City"
                  inputProps={{ maxLength: 30 }}
                  variant="outlined"
                  value={orgCity}
                  type="text"
                  style={{ width: "40%" }}
                  onChange={(e) => {
                    setOrgCity(e.target.value);
                  }}
                />
              </div>
              <div className="edit-organization-address">
                <TextField
                  fullWidth
                  label="State"
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  value={orgState}
                  type="text"
                  style={{ width: "32%" }}
                  onChange={(e) => {
                    setOrgState(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  label="Country"
                  variant="outlined"
                  value={orgCountry}
                  inputProps={{ maxLength: 30 }}
                  type="text"
                  style={{ width: "32%" }}
                  onChange={(e) => {
                    setOrgCountry(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  label="Zip Code"
                  variant="outlined"
                  value={orgZipCode}
                  inputProps={{
                    max: 9999999999999, // Allows up to 14 digits
                  }}
                  type="number"
                  style={{ width: "32%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 14) {
                      setOrgZipCode(value);
                    }
                  }}
                
                />
              </div>
              <div className="edit-organization-logo">
                <label htmlFor="update-organization-logo">
                  Update Organization Logo (Recommended Ratio - 1:1)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="update-organization-logo"
                  onChange={(e) => {
                    const size = e.target.files[0]?.size;
                    if (isStorageLimitReached(size))
                      return setShowStorageLimitReachedPopup(true);
                    if (size > 10000000) {
                      toast("File size excceded 10MB", { type: "info" });
                    } else {
                      setLoading();
                      const image_status = sendImageAPI(
                        token,
                        e.target.files[0],
                        `profile/${my_id}`,
                        general_info?.logo ? general_info?.logo : ""
                      );
                      image_status
                        .then((res) => {
                          if (res?.status) {
                            res?.json().then((response) => {
                              resetLoading();
                              if (response?.status) {
                                if (response?.message?.includes("limit"))
                                  return toast(response?.message, {
                                    type: "info",
                                  });
                                setOrgLogo(response?.data);
                              } else {
                                toast(response?.message, { type: "error" });
                              }
                            });
                          } else {
                            toast("Failed! Try again later", {
                              type: "error",
                            });
                          }
                        })
                        .catch(() =>
                          toast("Failed! Try again later", {
                            type: "error",
                          })
                        );
                    }
                  }}
                />
              </div>
              <div className="edit-organization-timezone">
                <RenderTimeZone
                  getTimezone={getTimezone}
                  codee={country_code}
                  zonee={corresponding_zone}
                />
              </div>
              <Button
                className="form-button"
                type="submit"
                variant="contained"
                style={{ background: "#446FA2" }}
              >
                Save Changes
              </Button>
            </form>
          </div>
        </div>
      </div>
      {loader}
      <div style={{ position: "absolute", left: 0 }}>
        {showStorageLimitReachedPopup && (
          <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
        )}
      </div>
    </>
  ) : null;
};

export default EditOrganization;
