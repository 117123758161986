import "./UserManagement.css";

import React, { useEffect, useRef, useState } from "react";
import {
  addUserAccess,
  removeUserAccess,
  updateUserAccess,
} from "Config/API/action";
import {
  getAllAdminRightsManagement,
  getAllSubscriptionManagement,
  getAssetPermissions,
  getCategoryData,
  getLocationData,
  getUserListCreditManagement,
  getUserListFileManagement,
  getUserListPortfolioAndWorkbookManagement,
  getUserListServiceTicketManagement,
  getUserListUserManagement,
} from "Config/API/get";

import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssetManagementIcon from "Media/Group 746.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import UserManagementInformationPopup from "Components/Popup/UserManagementInfoPop/PureComponents/UserManagementInformationPopup";
import debitCardIcon from "Media/icons/debit-cards@2x.png";
import folderIcon from "Media/icons/folder@2x.png";
import plusIcon from "Media/icons/+@2x.png";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import profilePic from "Media/blank-profile-picture.png";
import subscriptionIcon from "Media/subscription@2x.png";
import teamIcon from "Media/icons/team-management (1)@2x.png";
import ticketIcon from "Media/icons/airplane-ticket@2x.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const UserManagement = () => {
  const { state } = useLocation();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const [userList, setUserList] = useState("");
  const [addAccessList, setAddAccessList] = useState("");
  const [addServiceAccessList, setAddServiceAccessList] = useState("");
  const [addCreditAccessList, setAddCreditAccessList] = useState("");
  const [addSubscriptionAccessList, setAddSubscriptionAccessList] =
    useState("");
  const [addAdminRightsAccessList, setAddAdminRightsAccessList] = useState("");
  const [assetManagementRightsAccessList, setassetManagementRightsAccessList] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    state?.tab ? state?.tab : "generalUser"
  );
  const [selectedScope, setSelectedScope] = useState("external");
  const [locationList, setLocationList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState("");

  const [allMembers, setAllMembers] = useState([]);
  const [allFileMembers, setAllFileMembers] = useState([]);
  const [allCreditMembers, setAllCreditMembers] = useState([]);
  const [allPortfolioAndWorkbookMembers, setAllPortfolioAndWorkbookMembers] =
    useState([]);
  const [allSubscriptionManagement, setAllSubscriptionManagement] = useState(
    []
  );
  const [assetManagementList, setAssetManagementList] = useState([]);


  const [allAdminRightsManagement, setAllAdminRightsManagement] = useState([]);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState([[]]);
  const [infoHeading, setInfoHeading] = useState("");
  const loggedInUser = useSelector((state) => state.authenticationReducer);

  const selectRef = useRef(null);
  useOutsideAlerter(selectRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      // function handleClickOutside(event) {
      //   // separate condition for input tag
      //   if (ref.current && ref.current.contains(event.target)) {     //trying to access classList on null if ref.current is not properly initialized or if the component has unmounted
      //     ref.current.classList.add("show-input-box");
      //   }

      //   if (ref.current && !ref.current.contains(event.target)) {
      //     ref.current.classList.add("hide-dropdown");
      //     ref.current.classList.remove("show-input-box");
      //   } else {
      //     if (ref.current?.toString() !== "null") {
      //       ref.current.classList.remove("hide-dropdown");
      //     }
      //   }
      // }
      function handleClickOutside(event) {
        // Check if ref.current is defined
        if (ref.current) {
          // Check if the click is inside the referenced element
          if (ref.current.contains(event.target)) {
            ref.current.classList.add("show-input-box");
          } else {
            ref.current.classList.add("hide-dropdown");
            ref.current.classList.remove("show-input-box");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    getSubscriptionManagementData();
    getPortfolioManagementData();
    getCreditManagementData();
    getFileManagementData();
    getUserManagementData();
    getAdminRightsManagementData();
    getAssetManagementData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedScope === "external" || selectedTeamId !== "")
      getServiceTicketManagementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope, selectedTeamId]);

  useEffect(() => {
    if (selectedTab === "serviceTicket") {
      getAllLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedLocationId) {
      setUserList([]);
      getAllTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId]);

  const getSubscriptionManagementData = () => {
    const allSubscriptionManagementResponse = getAllSubscriptionManagement(
      token,
      "true",
      ""
    );
    allSubscriptionManagementResponse.then((res) => {
      if (res?.status) {
        setAllSubscriptionManagement(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getPortfolioManagementData = () => {
    const user_portfolio_and_workbook_management =
      getUserListPortfolioAndWorkbookManagement(token);
    user_portfolio_and_workbook_management.then((res) => {
      if (res?.status) {
        setAllPortfolioAndWorkbookMembers(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getCreditManagementData = () => {
    const user_with_credit_access_list = getUserListCreditManagement(
      token,
      "true",
      ""
    );
    user_with_credit_access_list.then((res) => {
      if (res?.status) {
        setAllCreditMembers(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getFileManagementData = () => {
    const user_file_management = getUserListFileManagement(token);
    user_file_management.then((res) => {
      if (res?.status) {
        setAllFileMembers(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getUserManagementData = () => {
    const user_with_user_management_access_list = getUserListUserManagement(
      token,
      "true",
      ""
    );
    user_with_user_management_access_list.then((res) => {
      if (res?.status) {
        setAllMembers(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getServiceTicketManagementData = () => {
    const user_with_access_list = getUserListServiceTicketManagement(
      token,
      selectedScope === "external" ? "true" : "",
      "",
      selectedScope,
      selectedLocationId,
      selectedTeamId
    );
    user_with_access_list.then((res) => {
      if (res?.status) {
        setUserList(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getAdminRightsManagementData = () => {
    const allAdminRightsResponse = getAllAdminRightsManagement(
      token,
      "true",
      ""
    );
    allAdminRightsResponse.then((res) => {
      if (res?.status) {
        setAllAdminRightsManagement(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };
  const getAssetManagementData = () => {
    const getassetmanagementuserlist =  getAssetPermissions(token);
    getassetmanagementuserlist.then((res) => {
      if (res?.status) {
        setassetManagementRightsAccessList(res?.data?.emp);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };
  const getAllLocations = () => {
    const locationList = getLocationData(token, loggedInUser?.id);
    locationList.then((res) => {
      if (res?.location?.length > 0) {
        setLocationList(res?.location);
      } else {
        setLocationList([]);
      }
    });
  };

  const getAllTeams = () => {
    setLoader();
    const categoryList = getCategoryData(
      token,
      loggedInUser?.id,
      selectedLocationId
    );
    categoryList
      .then((res) => {
        resetLoader();
        setTeamList(res?.category);
      })
      .catch(() => {
        resetLoader();
        toast("Failed! Try Again Later", {
          type: "error",
        });
      });
  };

  return (
    <div className="stusermng-parent">
      <div className="stusermng-header">
        <Headers />
      </div>

      <div className="stusermng-navigation">
        <Navigation />
      </div>

      <div className="stusermng-main">
        <div className="stusermng-heading">
          <p>User Management</p>
        </div>
        <div className="stusermng-wrapper">
          <div className="stusermng-btn-wrapper">
            <div
              className={`${
                selectedTab?.toString() === "generalUser" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("generalUser");
                  setAddServiceAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={teamIcon}
                  alt="General user icon"
                  style={{
                    width: "50px",
                    height: "35px",
                    objectFit: "cover",
                  }}
                />
                <span>General User Management</span>
              </button>
              {selectedTab?.toString() === "generalUser" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("General User Management");
                    setTabsText([
                      ["User Manager", "Ability to add, edit, delete users."],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "fileManagement" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("fileManagement");
                  setAddAccessList("");
                  setAddServiceAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={folderIcon}
                  alt="Folder icon"
                  style={{
                    width: "28px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span>File Management</span>
              </button>
              {selectedTab?.toString() === "fileManagement" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("File Management");
                    setTabsText([
                      [
                        "File Manager",
                        "Ability to Add, Rename, Move and Delete Files. Ability to Create QR Codes.",
                      ],
                      [
                        "Write Rights",
                        "Ability to Add, Rename, Move and Delete Files. Ability to Create QR Codes.",
                      ],
                      [
                        "Read Rights",
                        "Ability to See and View Files & Folders (this is by default for all users).",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "serviceTicket" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("serviceTicket");
                  setAddAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={ticketIcon}
                  alt="Ticket icon"
                  style={{
                    width: "34px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span>Service Ticket Management</span>
              </button>
              {selectedTab?.toString() === "serviceTicket" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Service Ticket Management");
                    setTabsText([
                      [
                        "Ticket Manager",
                        "Ability to Create, Edit and Reassign Service Tickets.",
                      ],
                      [
                        "External Ticket",
                        "Ability to Create External Tickets.",
                      ],
                      [
                        "Service Request Notification",
                        "Notification of External Service Requests.",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "creditHandling" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("creditHandling");
                  setAddAccessList("");
                  setAddServiceAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={debitCardIcon}
                  alt="Credit card icon"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span>Credit / Money Handling</span>
              </button>
              {selectedTab?.toString() === "creditHandling" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Credit / Money Handling");
                    setTabsText([
                      [
                        "Account Manager",
                        "Ability to Change Credit Card information, Request Transfer Out.",
                      ],
                      [
                        "Add Credit",
                        "Ability to Add Credit to Organizations Page.",
                      ],
                      [
                        "Read Rights",
                        "Ability to view Organizations Credit and Transaction Activity.",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "portfolioAndWorkbook" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("portfolioAndWorkbook");
                  setAddAccessList("");
                  setAddServiceAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={portfolioFiles}
                  alt="portfolio"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span>Portfolio / Workbook Management</span>
              </button>
              {selectedTab?.toString() === "portfolioAndWorkbook" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Portfolio / Workbook Management");
                    setTabsText([
                      [
                        "Portfolio Write Rights",
                        "Ability to Add, Rename, Move and Delete Files within Organizations Portfolio.",
                      ],
                      [
                        "Workbook Write Rights",
                        "Ability to Create, Add, Edit and Delete Files within Workbooks.",
                      ],
                      [
                        "Read Rights",
                        "Ability to View Portfolio Files and Workbooks Create (this is by default for all users).",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "subscriptionManagement" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                onClick={() => {
                  setSelectedTab("subscriptionManagement");
                  setAddAccessList("");
                  setAddServiceAccessList("");
                  setAddCreditAccessList("");
                }}
              >
                <img
                  src={subscriptionIcon}
                  alt="Subscription icon"
                  style={{
                    width: "28px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span>Subscription Management</span>
              </button>
              {selectedTab?.toString() === "subscriptionManagement" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Subscription Management");
                    setTabsText([
                      [
                        "Portfolio Subscription",
                        "Ability to Add and Remove Subscription to Outside Organizations Portfolio Subscription.",
                      ],
                      [
                        "Service Ticket Subscription",
                        "Ability to Add or Remove Subscription to Amnius Service Ticketing Feature.",
                      ],
                      [
                        "Cloud Storage Subscription",
                        "Ability to Add or Edit Subscription to Amnius Cloud Storage Amount.",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "assetManagement" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                style={{ gap: "5px" }}
                onClick={() => {
                  setSelectedTab("assetManagement");

                  setAddAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <img
                  src={AssetManagementIcon}
                  alt="Asset Management"
                  style={{ width: 30, height: 30 }}
                />

                <span>Asset Management</span>
              </button>
              {selectedTab?.toString() === "assetManagement" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Asset Management");
                    setTabsText([
                      [
                        "Asset Manager",
                        "Ability to Create, Edit and Share Assets.",
                      ],
                      [
                        "Asset Request Notification",
                        "Notification of Service Requests.",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
            <div
              className={`${
                selectedTab?.toString() === "adminRights" &&
                "user-management-active"
              } relative-container`}
            >
              <button
                className="change-selected-tab-button"
                style={{ gap: "5px" }}
                onClick={() => {
                  setSelectedTab("adminRights");
                  setAddAccessList("");
                  setAddServiceAccessList("");
                  setAddCreditAccessList("");
                  setAddSubscriptionAccessList("");
                }}
              >
                <AdminPanelSettingsIcon
                  style={{ color: "#446fa2", height: 37, width: 37 }}
                />
                <span>Admin Rights</span>
              </button>
              {selectedTab?.toString() === "adminRights" && (
                <QuestionMarkIcon
                  className="user-management-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setInfoHeading("Admin Rights");
                    setTabsText([
                      [
                        "Admin Rights",
                        "Ability to Perform All Actions that an Admin can Perform.",
                      ],
                    ]);
                  }}
                />
              )}
            </div>
          </div>

          {/* General User Management Tab */}
          {selectedTab?.toString() === "generalUser" && (
            <>
              {loggedInUser?.admin_account_manager === "manager" ? (
                <div
                  className="stusermng-add-user"
                  ref={selectRef}
                  onClick={() => {
                    const add_access_list = getUserListUserManagement(
                      token,
                      "",
                      ""
                    );
                    add_access_list.then((res) => {
                      if (res?.status) {
                        setAddAccessList(res?.data?.emp);
                      }
                    });
                  }}
                >
                  <img src={plusIcon} alt="add icon" />
                  <span>Add Access</span>

                  <input
                    type="search"
                    className="stusermng-search-bar"
                    placeholder="Search Users"
                    onChange={(e) => {
                      const add_access_list = getUserListUserManagement(
                        token,
                        "",
                        e.target.value
                      );
                      add_access_list.then((res) => {
                        if (res?.status) {
                          setAddAccessList(res?.data?.emp);
                        }
                      });
                    }}
                  />

                  {/* add access user list */}
                  <div className="stusermng-user-list">
                    {addAccessList ? (
                      addAccessList?.length?.toString() !== "0" ? (
                        addAccessList?.map((curr, index) => {
                          return (
                            <div
                              className="stusermng-user-data"
                              key={index}
                              onClick={() => {
                                const user_status = addUserAccess(
                                  token,
                                  "general_user_mangement",
                                  "read",
                                  "",
                                  curr?.id
                                );
                                user_status.then((res) => {
                                  if (res?.status) {
                                    toast(res?.message, {
                                      type: "success",
                                    });
                                  }
                                  getUserManagementData();
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="Profile"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>

                              <AddIcon />
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="stusermng-head-wrapper">
                <div className="stusermng-head-rows">USER NAME</div>
                <div className="stusermng-head-rows">USER MANAGER</div>
                <div className="stusermng-head-rows">REMOVE ACCESS</div>
              </div>
              <div className="stusermng-user-table">
                {allMembers ? (
                  allMembers?.length > 0 ? (
                    allMembers?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-rows">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-rows">
                            {curr?.permission_user_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getUserManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#919191", fontSize: "22px" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getUserManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>

                          <div className="stusermng-body-rows">
                            <CloseIcon
                              style={{
                                color: "#D81C1C",
                                border: "solid 1px #D81C1C",
                                borderRadius: "5px",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (
                                  loggedInUser?.admin_account_manager ===
                                  "manager"
                                ) {
                                  const user_status = removeUserAccess(
                                    token,
                                    curr?.management_type,
                                    curr?.id
                                  );
                                  user_status.then((res) => {
                                    if (res?.status) {
                                      toast(res?.message, {
                                        type: "success",
                                      });
                                    }
                                    getUserManagementData();
                                  });
                                } else {
                                  toast("You do not have access", {
                                    type: "info",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            </>
          )}

          {/* File Management Tab */}
          {selectedTab?.toString() === "fileManagement" && (
            <>
              <div className="stusermng-head-wrapper">
                <div className="stusermng-head-row">USER NAME</div>
                <div className="stusermng-head-row">FILE MANAGER</div>
                <div className="stusermng-head-row">WRITE RIGHTS</div>
                <div className="stusermng-head-row">READ RIGHTS</div>
              </div>
              <div className="stusermng-user-table">
                {allFileMembers ? (
                  allFileMembers?.length > 0 ? (
                    allFileMembers?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-row">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_file_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "file_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getFileManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#919191", fontSize: "22px" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "file_manager",
                                      curr?.id
                                    );
                                     updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "write_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getFileManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_write_rights?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "write_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getFileManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#919191", fontSize: "22px" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "write_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getFileManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_read_rights?.toString() ===
                              "write" && (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    curr?.permission_file_manager?.toString() ===
                                      "write" ||
                                    curr?.permission_write_rights?.toString() ===
                                      "write"
                                  ) {
                                    if (
                                      loggedInUser?.admin_account_manager ===
                                      "manager"
                                    ) {
                                      const user_status = removeUserAccess(
                                        token,
                                        curr?.management_type,
                                        curr?.id
                                      );
                                      user_status.then((res) => {
                                        if (res?.status) {
                                          toast(res?.message, {
                                            type: "success",
                                          });
                                        }
                                        getFileManagementData();
                                      });
                                    } else {
                                      toast("You do not have access", {
                                        type: "info",
                                      });
                                    }
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            </>
          )}

          {/* Service Ticket Tab */}
          {selectedTab?.toString() === "serviceTicket" && (
            <>
              <div className="stusermng-scope-container">
                <button
                  className={`${
                    selectedScope === "internal" &&
                    "stusermng-scope-container-button-active"
                  }`}
                  onClick={() => {
                    if (selectedScope !== "internal") {
                      setUserList([]);
                      setAddServiceAccessList("");
                      setSelectedScope("internal");
                    }
                  }}
                >
                  INTERNAL
                </button>
                <button
                  className={`${
                    selectedScope === "external" &&
                    "stusermng-scope-container-button-active"
                  }`}
                  onClick={() => {
                    if (selectedScope !== "external") {
                      setUserList([]);
                      setAddServiceAccessList("");
                      setSelectedScope("external");
                      setSelectedLocationId("");
                      setSelectedTeamId("");
                    }
                  }}
                >
                  EXTERNAL
                </button>
              </div>
              {selectedScope === "internal" && (
                <div
                  style={{ marginTop: "-35px" }}
                  className="stusermng-scope-container"
                >
                  <select
                    value={selectedLocationId}
                    onChange={(e) => {
                      setSelectedLocationId(e.target.value);
                      setSelectedTeamId("");
                    }}
                  >
                    <option value="">Location</option>
                    {locationList?.map((item) => (
                      <option
                        key={item?.key}
                        label={item?.location}
                        value={item?.id}
                      >
                        {item?.location}
                      </option>
                    ))}
                  </select>
                  <select
                    value={selectedTeamId}
                    disabled={selectedLocationId === ""}
                    onChange={(e) => {
                      setSelectedTeamId(e.target.value);
                    }}
                  >
                    <option value="">Team</option>
                    {teamList?.length > 0 && (
                      <>
                        {teamList?.map((item) => (
                          <option key={item?.key} value={item?.id}>
                            {item?.category_id}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              )}
              {loggedInUser?.admin_account_manager === "manager" &&
              selectedScope === "external" ? (
                <div
                  className="stusermng-add-user"
                  style={{ marginTop: "-35px" }}
                  ref={selectRef}
                  onClick={() => {
                    const add_access_list = getUserListServiceTicketManagement(
                      token,
                      "",
                      "",
                      selectedScope,
                      selectedLocationId,
                      selectedTeamId
                    );
                    add_access_list.then((res) => {
                      if (res?.status) {
                        setAddServiceAccessList(res?.data?.emp);
                      }
                    });
                  }}
                >
                  <img src={plusIcon} alt="add icon" />
                  <span>Add Access</span>

                  <input
                    type="search"
                    className="stusermng-search-bar"
                    placeholder="Search Users"
                    onChange={(e) => {
                      const add_access_list =
                        getUserListServiceTicketManagement(
                          token,
                          "",
                          e.target.value,
                          selectedScope,
                          selectedLocationId,
                          selectedTeamId
                        );
                      add_access_list.then((res) => {
                        if (res?.status) {
                          setAddServiceAccessList(res?.data?.emp);
                        }
                      });
                    }}
                  />

                  {/* add access user list */}
                  <div className="stusermng-user-list">
                    {addServiceAccessList ? (
                      addServiceAccessList?.length?.toString() !== "0" ? (
                        addServiceAccessList?.map((curr, index) => {
                          return (
                            <div
                              className="stusermng-user-data"
                              key={index}
                              onClick={() => {
                                const user_status = addUserAccess(
                                  token,
                                  "service_ticketing",
                                  "read",
                                  "",
                                  curr?.id,
                                  selectedScope,
                                  selectedLocationId,
                                  selectedTeamId
                                );
                                user_status.then((res) => {
                                  if (res?.status) {
                                    toast(res?.message, {
                                      type: "success",
                                    });
                                  }
                                  getServiceTicketManagementData();
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="Profile"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>

                              <AddIcon />
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div class="subscription-head">
                <div class="subscription-Body">
                  <div className="subscription-head-wrapper">
                    <div className="stusermng-head-row subscription">
                      USER NAME
                    </div>
                    <div className="stusermng-head-row subscription">
                      TICKET MANAGER
                    </div>
                    <div className="stusermng-head-row subscription">
                      SERVICE REQUEST NOTIFICATION
                    </div>
                    <div className="stusermng-head-row subscription">
                      {selectedScope === "external"
                        ? "REMOVE ACCESS"
                        : "READ RIGHTS"}
                    </div>
                  </div>
                  <div className="subscription-user-table">
                    {userList ? (
                      userList?.length ? (
                        userList?.map((curr, index) => {
                          return (
                            <div className="stusermng-body-wrapper" key={index}>
                              <div className="stusermng-body-row-subscribe userdetai">
                                <div>
                                  <div
                                    className="stusermng-body-internal"
                                    style={{
                                      position: "relative",
                                      width: "fit-content",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      src={
                                        curr?.image ? curr?.image : profilePic
                                      }
                                      alt="logo"
                                    />
                                    {curr?.online_status?.toString() ===
                                    "online" ? (
                                      <FiberManualRecordIcon
                                        style={{ color: "#87c823" }}
                                      />
                                    ) : (
                                      <FiberManualRecordIcon
                                        style={{ color: "#c23616" }}
                                      />
                                    )}
                                  </div>
                                  <span>
                                    {curr?.username
                                      ? curr?.username
                                      : "No Name"}
                                  </span>
                                </div>
                              </div>
                              <div className="stusermng-body-row">
                                {(selectedScope === "external" &&
                                  curr?.permission_external_ticket_manager?.toString() ===
                                    "write") ||
                                (selectedScope === "internal" &&
                                  curr?.permission_internal_ticket_manager?.toString() ===
                                    "write") ? (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "read",
                                          selectedScope === "external"
                                            ? "external_ticket_manager"
                                            : "internal_ticket_manager",
                                          curr?.id,
                                          selectedScope,
                                          selectedLocationId,
                                          selectedTeamId
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getServiceTicketManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlankIcon
                                    style={{
                                      color: "#919191",
                                      fontSize: "22px",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "write",
                                          selectedScope === "external"
                                            ? "external_ticket_manager"
                                            : "internal_ticket_manager",
                                          curr?.id,
                                          selectedScope,
                                          selectedLocationId,
                                          selectedTeamId
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getServiceTicketManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              <div className="stusermng-body-row">
                                {((selectedScope === "external" &&
                                  curr?.permission_external_service_ticket_notification?.toString()) ||
                                  (selectedScope === "internal" &&
                                    curr?.permission_internal_service_ticket_notification?.toString())) ===
                                "write" ? (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "read",
                                          selectedScope === "external"
                                            ? "external_service_ticket_notification"
                                            : "internal_service_ticket_notification",
                                          curr?.id,
                                          selectedScope,
                                          selectedLocationId,
                                          selectedTeamId
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getServiceTicketManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlankIcon
                                    style={{
                                      color: "#919191",
                                      fontSize: "22px",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "write",
                                          selectedScope === "external"
                                            ? "external_service_ticket_notification"
                                            : "internal_service_ticket_notification",
                                          curr?.id,
                                          selectedScope,
                                          selectedLocationId,
                                          selectedTeamId
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getServiceTicketManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              <div className="stusermng-body-row">
                                {selectedScope === "external" ? (
                                  <CloseIcon
                                    style={{
                                      color: "#D81C1C",
                                      border: "solid 1px #D81C1C",
                                      borderRadius: "5px",
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = removeUserAccess(
                                          token,
                                          curr?.management_type,
                                          curr?.id,
                                          selectedScope,
                                          selectedLocationId,
                                          selectedTeamId
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getServiceTicketManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        curr?.permission_internal_ticket_manager?.toString() ===
                                          "write" ||
                                        curr?.permission_internal_service_ticket_notification?.toString() ===
                                          "write"
                                      ) {
                                        if (
                                          loggedInUser?.admin_account_manager ===
                                          "manager"
                                        ) {
                                          const user_status = removeUserAccess(
                                            token,
                                            curr?.management_type,
                                            curr?.id,
                                            selectedScope,
                                            selectedLocationId,
                                            selectedTeamId
                                          );
                                          user_status.then((res) => {
                                            if (res?.status) {
                                              toast(res?.message, {
                                                type: "success",
                                              });
                                            }
                                            getServiceTicketManagementData();
                                          });
                                        } else {
                                          toast("You do not have access", {
                                            type: "info",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : (
                      <div className="stusermng-msg">No user found</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Credit Handling Tab */}
          {selectedTab?.toString() === "creditHandling" && (
            <>
              {loggedInUser?.admin_account_manager === "manager" ? (
                <div
                  className="stusermng-add-user"
                  ref={selectRef}
                  onClick={() => {
                    const add_access_list = getUserListCreditManagement(
                      token,
                      "",
                      ""
                    );
                    add_access_list.then((res) => {
                      if (res?.status) {
                        setAddCreditAccessList(res?.data?.emp);
                      }
                    });
                  }}
                >
                  <img src={plusIcon} alt="add icon" />
                  <span>Add Access</span>

                  <input
                    type="search"
                    className="stusermng-search-bar"
                    placeholder="Search Users"
                    onChange={(e) => {
                      const add_access_list = getUserListCreditManagement(
                        token,
                        "",
                        e.target.value
                      );
                      add_access_list.then((res) => {
                        if (res?.status) {
                          setAddCreditAccessList(res?.data?.emp);
                        }
                      });
                    }}
                  />

                  {/* add access user list */}
                  <div className="stusermng-user-list">
                    {addCreditAccessList ? (
                      addCreditAccessList?.length?.toString() !== "0" ? (
                        addCreditAccessList?.map((curr, index) => {
                          return (
                            <div
                              className="stusermng-user-data"
                              key={index}
                              onClick={() => {
                                const user_status = addUserAccess(
                                  token,
                                  "credit_money_handeling",
                                  "read",
                                  "",
                                  curr?.id
                                );
                                user_status.then((res) => {
                                  if (res?.status) {
                                    toast(res?.message, {
                                      type: "success",
                                    });
                                  }
                                  getCreditManagementData();
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="Profile"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>

                              <AddIcon />
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="stusermng-head-wrapper">
                <div className="stusermng-head-row">USER NAME</div>
                <div className="stusermng-head-row">ACCOUNT MANAGER</div>
                <div className="stusermng-head-row">ADD CREDIT</div>
                <div className="stusermng-head-row">READ RIGHTS</div>
                <div className="stusermng-head-row">REMOVE ACCESS</div>
              </div>
              <div className="stusermng-user-table">
                {allCreditMembers ? (
                  allCreditMembers?.length > 0 ? (
                    allCreditMembers?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-row">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_account_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "account_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "account_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_credit_rights?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "credit_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "credit_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_read_rights?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "read_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "read_rights",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getCreditManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            <CloseIcon
                              style={{
                                color: "#D81C1C",
                                border: "solid 1px #D81C1C",
                                borderRadius: "5px",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (
                                  loggedInUser?.admin_account_manager ===
                                  "manager"
                                ) {
                                  const user_status = removeUserAccess(
                                    token,
                                    curr?.management_type,
                                    curr?.id
                                  );
                                  user_status.then((res) => {
                                    if (res?.status) {
                                      toast(res?.message, {
                                        type: "success",
                                      });
                                    }
                                    getCreditManagementData();
                                  });
                                } else {
                                  toast("You do not have access", {
                                    type: "info",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            </>
          )}

          {/* File Management Tab */}
          {selectedTab?.toString() === "portfolioAndWorkbook" && (
            <>
              <div className="stusermng-head-wrapper">
                <div className="stusermng-head-row">USER NAME</div>
                <div className="stusermng-head-row">PORTFOLIO WRITE RIGHTS</div>
                <div className="stusermng-head-row">WORKBOOK WRITE RIGHTS</div>
                <div className="stusermng-head-row">READ RIGHTS</div>
              </div>
              <div className="stusermng-user-table">
                {allPortfolioAndWorkbookMembers ? (
                  allPortfolioAndWorkbookMembers?.length > 0 ? (
                    allPortfolioAndWorkbookMembers?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-row-subscribe">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_portfolio_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "portfolio_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getPortfolioManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "portfolio_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getPortfolioManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_workbook_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "workbook_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getPortfolioManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "workbook_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getPortfolioManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_read_rights?.toString() ===
                              "write" && (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    curr?.permission_portfolio_manager?.toString() ===
                                      "write" ||
                                    curr?.permission_workbook_manager?.toString() ===
                                      "write"
                                  ) {
                                    if (
                                      loggedInUser?.admin_account_manager ===
                                      "manager"
                                    ) {
                                      const user_status = removeUserAccess(
                                        token,
                                        curr?.management_type,
                                        curr?.id
                                      );
                                      user_status.then((res) => {
                                        if (res?.status) {
                                          toast(res?.message, {
                                            type: "success",
                                          });
                                        }
                                        getPortfolioManagementData();
                                      });
                                    } else {
                                      toast("You do not have access", {
                                        type: "info",
                                      });
                                    }
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            </>
          )}

          {/* Subscription Management Tab */}
          {selectedTab?.toString() === "subscriptionManagement" && (
            <>
              {loggedInUser?.admin_account_manager === "manager" ? (
                <div
                  className="stusermng-add-user"
                  ref={selectRef}
                  onClick={() => {
                    const add_access_list = getAllSubscriptionManagement(
                      token,
                      "",
                      ""
                    );
                    add_access_list.then((res) => {
                      if (res?.status) {
                        setAddSubscriptionAccessList(res?.data?.emp);
                      }
                    });
                  }}
                >
                  <img src={plusIcon} alt="add icon" />
                  <span>Add Access</span>
                  <input
                    type="search"
                    className="stusermng-search-bar"
                    placeholder="Search Users"
                    onChange={(e) => {
                      const add_access_list = getAllSubscriptionManagement(
                        token,
                        "",
                        e.target.value
                      );
                      add_access_list.then((res) => {
                        if (res?.status) {
                          setAddSubscriptionAccessList(res?.data?.emp);
                        }
                      });
                    }}
                  />

                  {/* add access user list */}
                  <div className="stusermng-user-list">
                    {addSubscriptionAccessList ? (
                      addSubscriptionAccessList?.length?.toString() !== "0" ? (
                        addSubscriptionAccessList?.map((curr, index) => {
                          return (
                            <div
                              className="stusermng-user-data"
                              key={index}
                              onClick={() => {
                                const user_status = addUserAccess(
                                  token,
                                  "subscription_management",
                                  "read",
                                  "subscription_approval",
                                  curr?.id
                                );
                                user_status.then((res) => {
                                  if (res?.status) {
                                    toast(res?.message, {
                                      type: "success",
                                    });
                                  }
                                  getSubscriptionManagementData();
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="Profile"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>

                              <AddIcon />
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="subscription-head">
                <div className="subscription-Body">
                  <div className="subscription-head-wrapper">
                    <div className="stusermng-head-row subscription">
                      USER NAME
                    </div>
                    <div className="stusermng-head-row subscription">
                      PORTFOLIO SUBSCRIPTION
                    </div>
                    <div className="stusermng-head-row subscription">
                      SERVICE TICKET SUBSCRIPTION
                    </div>
                    <div className="stusermng-head-row subscription">
                      CLOUD STORAGE SUBSCRIPTION
                    </div>
                    <div className="stusermng-head-row subscription">
                      REMOVE ACCESS
                    </div>
                  </div>
                  <div className="subscription-user-table">
                    {allSubscriptionManagement ? (
                      allSubscriptionManagement?.length > 0 ? (
                        allSubscriptionManagement?.map((curr, index) => {
                          return (
                            <div className="stusermng-body-wrapper" key={index}>
                              <div className="stusermng-body-row-subscribe userdetail">
                                <div>
                                  <div
                                    className="stusermng-body-internal"
                                    style={{
                                      position: "relative",
                                      width: "fit-content",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      src={
                                        curr?.image ? curr?.image : profilePic
                                      }
                                      alt="logo"
                                    />
                                    {curr?.online_status?.toString() ===
                                    "online" ? (
                                      <FiberManualRecordIcon
                                        style={{ color: "#87c823" }}
                                      />
                                    ) : (
                                      <FiberManualRecordIcon
                                        style={{ color: "#c23616" }}
                                      />
                                    )}
                                  </div>
                                  <span>
                                    {curr?.username
                                      ? curr?.username
                                      : "No Name"}
                                  </span>
                                </div>
                              </div>

                              <div className="stusermng-body-row">
                                {curr?.permission_subscription_approval?.toString() ===
                                "write" ? (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "read",
                                          "subscription_approval",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlankIcon
                                    style={{
                                      color: "#919191",
                                      fontSize: "22px",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "write",
                                          "subscription_approval",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>

                              <div className="stusermng-body-row">
                                {curr?.permission_ticket_subscription?.toString() ===
                                "write" ? (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "read",
                                          "ticket_subscription",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlankIcon
                                    style={{
                                      color: "#919191",
                                      fontSize: "22px",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "write",
                                          "ticket_subscription",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              <div className="stusermng-body-row">
                                {curr?.permission_storage_subscription?.toString() ===
                                "write" ? (
                                  <CheckBoxIcon
                                    style={{ color: "#4ACB12" }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "read",
                                          "storage_subscription",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlankIcon
                                    style={{
                                      color: "#919191",
                                      fontSize: "22px",
                                    }}
                                    onClick={() => {
                                      if (
                                        loggedInUser?.admin_account_manager ===
                                        "manager"
                                      ) {
                                        const user_status = updateUserAccess(
                                          token,
                                          curr?.management_type,
                                          "write",
                                          "storage_subscription",
                                          curr?.id
                                        );
                                        user_status.then((res) => {
                                          if (res?.status) {
                                            toast(res?.message, {
                                              type: "success",
                                            });
                                          }
                                          getSubscriptionManagementData();
                                        });
                                      } else {
                                        toast("You do not have access", {
                                          type: "info",
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              <div className="stusermng-body-row">
                                <CloseIcon
                                  style={{
                                    color: "#D81C1C",
                                    border: "solid 1px #D81C1C",
                                    borderRadius: "5px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      loggedInUser?.admin_account_manager ===
                                      "manager"
                                    ) {
                                      const user_status = removeUserAccess(
                                        token,
                                        curr?.management_type,
                                        curr?.id
                                      );
                                      user_status.then((res) => {
                                        if (res?.status) {
                                          toast(res?.message, {
                                            type: "success",
                                          });
                                        }
                                        getSubscriptionManagementData();
                                      });
                                    } else {
                                      toast("You do not have access", {
                                        type: "info",
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : (
                      <div className="stusermng-msg">No user found</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Admin Rights Tab */}
          {selectedTab?.toString() === "adminRights" && (
            <>
              {loggedInUser?.admin_account_manager === "manager" ? (
                <div
                  className="stusermng-add-user"
                  ref={selectRef}
                  onClick={() => {
                    const add_access_list = getAllAdminRightsManagement(
                      token,
                      "",
                      ""
                    );
                    add_access_list.then((res) => {
                      if (res?.status) {
                        setAddAdminRightsAccessList(res?.data?.emp);
                      }
                    });
                  }}
                >
                  <img src={plusIcon} alt="add icon" />
                  <span>Add Access</span>

                  <input
                    type="search"
                    className="stusermng-search-bar"
                    placeholder="Search Users"
                    onChange={(e) => {
                      const add_access_list = getAllAdminRightsManagement(
                        token,
                        "",
                        e.target.value
                      );
                      add_access_list.then((res) => {
                        if (res?.status) {
                          setAddAdminRightsAccessList(res?.data?.emp);
                        }
                      });
                    }}
                  />

                  {/* add access user list */}
                  <div className="stusermng-user-list">
                    {addAdminRightsAccessList ? (
                      addAdminRightsAccessList?.length?.toString() !== "0" ? (
                        addAdminRightsAccessList?.map((curr, index) => {
                          return (
                            <div
                              className="stusermng-user-data"
                              key={index}
                              onClick={() => {
                                const user_status = addUserAccess(
                                  token,
                                  "admin_handeling",
                                  "read",
                                  "admin_manager",
                                  curr?.id
                                );
                                user_status.then((res) => {
                                  if (res?.status) {
                                    toast(res?.message, {
                                      type: "success",
                                    });
                                  }
                                  getAdminRightsManagementData();
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="Profile"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>

                              <AddIcon />
                            </div>
                          );
                        })
                      ) : (
                        <div className="stusermng-msg">No user found</div>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="stusermng-head-wrapper">
                <div className="stusermng-head-rows">USER NAME</div>
                <div className="stusermng-head-rows">ADMIN RIGHTS</div>
                <div className="stusermng-head-rows">REMOVE ACCESS</div>
              </div>
              <div className="stusermng-user-table">
                {allAdminRightsManagement ? (
                  allAdminRightsManagement?.length > 0 ? (
                    allAdminRightsManagement?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-rows">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-rows">
                            {curr?.permission_admin_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "admin_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAdminRightsManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#919191", fontSize: "22px" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "admin_manager",
                                      curr?.id
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAdminRightsManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>

                          <div className="stusermng-body-rows">
                            <CloseIcon
                              style={{
                                color: "#D81C1C",
                                border: "solid 1px #D81C1C",
                                borderRadius: "5px",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (
                                  loggedInUser?.admin_account_manager ===
                                  "manager"
                                ) {
                                  const user_status = removeUserAccess(
                                    token,
                                    curr?.management_type,
                                    curr?.id
                                  );
                                  user_status.then((res) => {
                                    if (res?.status) {
                                      toast(res?.message, {
                                        type: "success",
                                      });
                                    }
                                    getAdminRightsManagementData();
                                  });
                                } else {
                                  toast("You do not have access", {
                                    type: "info",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            </>
          )}

          {/* Asset Management Tab */}
          {selectedTab?.toString() === "assetManagement" && (
            <>
                <div className="stusermng-head-wrapper">
                <div className="stusermng-head-rows">USER NAME</div>
                <div className="stusermng-head-rows"> ASSET MANAGER</div>
                <div className="stusermng-head-rows"> ASSET NOTIFICATION</div>
                <div className="stusermng-head-rows"> READ RIGHTS</div>
              </div>
              <div className="stusermng-user-table">
                {assetManagementRightsAccessList ? (
                  assetManagementRightsAccessList?.length > 0 ? (
                    assetManagementRightsAccessList?.map((curr, index) => {
                      return (
                        <div className="stusermng-body-wrapper" key={index}>
                          <div className="stusermng-body-row-subscribe">
                            <div>
                              <div
                                className="stusermng-body-internal"
                                style={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={curr?.image ? curr?.image : profilePic}
                                  alt="logo"
                                />
                                {curr?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    style={{ color: "#87c823" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ color: "#c23616" }}
                                  />
                                )}
                              </div>
                              <span>
                                {curr?.username ? curr?.username : "No Name"}
                              </span>
                            </div>
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_asset_manager?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "asset_manager",
                                      curr?.id,
                                      
                                    );
                                   
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAssetManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "asset_manager",
                                      curr?.id,
                                     
                                    );
                                    updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "asset_notification_rights",
                                      curr?.id,
                                   
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAssetManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_asset_notification_rights?.toString() ===
                            "write" ? (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "read",
                                      "asset_notification_rights",
                                      curr?.id,
                                   
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAssetManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: "#919191",
                                  fontSize: "22px",
                                }}
                                onClick={() => {
                                  if (
                                    loggedInUser?.admin_account_manager ===
                                    "manager"
                                  ) {
                                    const user_status = updateUserAccess(
                                      token,
                                      curr?.management_type,
                                      "write",
                                      "asset_notification_rights",
                                      curr?.id,
                                     
                                    );
                                    user_status.then((res) => {
                                      if (res?.status) {
                                        toast(res?.message, {
                                          type: "success",
                                        });
                                      }
                                      getAssetManagementData();
                                    });
                                  } else {
                                    toast("You do not have access", {
                                      type: "info",
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="stusermng-body-row">
                            {curr?.permission_read_rights?.toString() ===
                              "write" && (
                              <CheckBoxIcon
                                style={{ color: "#4ACB12" }}
                                onClick={() => {
                                  if (
                                    curr?.permission_asset_manager?.toString() ===
                                      "write" ||
                                    curr?.permission_asset_notification_rights?.toString() ===
                                      "write"
                                  ) {
                                    if (
                                      loggedInUser?.admin_account_manager ===
                                      "manager"
                                    ) {
                                      const user_status =removeUserAccess(
                                        token,
                                        curr?.management_type,
                                        curr?.id
                                      );
                                      user_status.then((res) => {
                                        if (res?.status) {
                                          toast(res?.message, {
                                            type: "success",
                                          });
                                        }
                                        getAssetManagementData();
                                      });
                                    } else {
                                      toast("You do not have access", {
                                        type: "info",
                                      });
                                    }
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stusermng-msg">No user found</div>
                  )
                ) : (
                  <div className="stusermng-msg">No user found</div>
                )}
              </div>
            
             
            </>
          )} 
        </div>
      </div>
      {infoPopup ? (
        <UserManagementInformationPopup
          message={tabsText}
          hide={setInfoPopup}
          infoHeading={infoHeading}
        />
      ) : null}
      {loader}
    </div>
  );
};

export default UserManagement;
