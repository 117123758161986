import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import TrashCard from "../PureComponents/TrashCard";
import TrashUsersCard from "Components/TrashUsersCard/TrashUsersCard";
import { getTrashDataByType } from "Config/API/get";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import TrashAssetCard from "Components/Cards/TrashAssetCard/trashAssetCard";

const RenderTrashCard = () => {
  const token = localStorage.getItem("token");
  const [trashData, setTrashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const loadingArray = ["", "", "", "", "", ""];

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getTrashDataHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getTrashDataHandler = () => {
    const response = getTrashDataByType(token, searchParams.get("type"));
    response
      .then((res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          if (res?.data?.subfolderData || res?.data?.asset?.length > 0) {
            // Merge subfolderData and asset data
            const combinedData = [
              ...(res?.data?.subfolderData || []), // add subfolderData if exists
              ...(res?.data?.asset?.map((item) => ({
                ...item,
                isAsset: true,
              })) || []), // add asset data with isAsset flag
            ];

            setTrashData(combinedData);
          } else if (res?.data?.userData) {
            setTrashData(res?.data?.userData);
          } else if (res?.data?.assets) {
            setTrashData(res?.data?.assets);
          }
        }
      })
      .catch((e) => {
        toast("Failed! to load trash data", { type: "error" });
      });
  };

  return (
    <>
      <div className="trash-upper">
        <label>Trash</label>
        <div className="render-trash-card-tabs-container">
          <Button
            style={
              searchParams.get("type") === "filesAndFolders"
                ? { backgroundColor: "rgb(92, 161, 39)" }
                : { backgroundColor: "rgb(68, 111, 162)" }
            }
            variant="contained"
            onClick={() => {
              if (searchParams.get("type") === "filesAndFolders") return;
              setIsLoading(true);
              setSearchParams({ type: "filesAndFolders" });
            }}
          >
            FILES AND FOLDERS
          </Button>
          <Button
            style={
              searchParams.get("type") === "users"
                ? { backgroundColor: "rgb(92, 161, 39)" }
                : { backgroundColor: "rgb(68, 111, 162)" }
            }
            variant="contained"
            onClick={() => {
              if (searchParams.get("type") === "users") return;
              setIsLoading(true);
              setSearchParams({ type: "users" });
            }}
          >
            ARCHIVED USERS
          </Button>
          <Button
            style={
              searchParams.get("type") === "assets"
                ? { backgroundColor: "rgb(92, 161, 39)" }
                : { backgroundColor: "rgb(68, 111, 162)" }
            }
            variant="contained"
            onClick={() => {
              if (searchParams.get("type") === "assets") return;
              setIsLoading(true);
              setSearchParams({ type: "assets" });
            }}
          >
            ARCHIVED ASSETS
          </Button>
        </div>
      </div>
      <div className="render-trash-card-tabs-content">
        {searchParams.get("type") === "filesAndFolders" &&
          (!isLoading ? (
            trashData.length > 0 ? (
              trashData?.map((item, index) => {
                console.log(item?.isAsset); // Logs whether the item is an asset
                const isAsset =
                  item?.isAsset || (item?.asset && item.asset.length > 0);

                return (
                  <div key={index}>
                    <TrashCard
                      getTrashDataHandler={getTrashDataHandler}
                      files={item}
                      assets={isAsset ? [item] : []}
                    />
                  </div>
                );
              })
            ) : (
              <p>No Data Found.</p>
            )
          ) : (
            loadingArray.map((item) => {
              return (
                <div
                  className="portfolio-content-item"
                  style={{ borderColor: "#ececec" }}
                >
                  <div>
                    <div className="loading-content-image animation" />
                    <span
                      className="loading-span animation"
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                  <div
                    className="loading-content-button animation"
                    style={{ position: "absolute", top: "10%", right: 0 }}
                  />
                </div>
              );
            })
          ))}
        {searchParams.get("type") === "users" &&
          (!isLoading ? (
            trashData.length > 0 ? (
              trashData?.map((item, index) => {
                return (
                  <TrashUsersCard
                    getTrashDataHandler={getTrashDataHandler}
                    userData={item}
                    key={index}
                  />
                );
              })
            ) : (
              <p>No Archived Users Found.</p>
            )
          ) : (
            loadingArray.map((item) => {
              return (
                <div style={{ marginTop: "20px" }}>
                  <div
                    className="portfolio-content-item"
                    style={{
                      borderColor: "#ececec",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div>
                      <div
                        className="animation"
                        style={{
                          height: "55px",
                          width: "55px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          flexDirection: "column",
                          gap: "3px",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "21.67px",
                            width: "170px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{ marginLeft: "10px", height: "11.67px" }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "100px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "130px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        height: "63px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ padding: "10px" }}
                        className="loading-content-button animation"
                      />
                      <div
                        style={{ padding: "10px" }}
                        className="loading-content-button animation"
                      />
                    </div>
                    <button
                      className="animation"
                      style={{
                        height: "21px",
                        width: "127px",
                        position: "absolute",
                        top: "-23px",
                        right: 0,
                        borderRadius: "5px 5px 1px 0",
                      }}
                    />
                  </div>
                </div>
              );
            })
          ))}
        {searchParams.get("type") === "assets" &&
          (!isLoading ? (
            trashData.length > 0 ? (
              trashData.map((item) => {
                return (
                  <TrashAssetCard
                    getTrashDataHandler={getTrashDataHandler}
                    userData={item} // Pass the single item here, not the whole trashData array
                    key={item.unique_id} // Use unique_id as key for each card
                  />
                );
              })
            ) : (
              <p>No Archived Assets Found.</p>
            )
          ) : (
            loadingArray.map((item) => {
              return (
                <div style={{ marginTop: "20px" }}>
                  <div
                    className="portfolio-content-item"
                    style={{
                      borderColor: "#ececec",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div>
                      <div
                        className="animation"
                        style={{
                          height: "55px",
                          width: "55px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          flexDirection: "column",
                          gap: "3px",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "21.67px",
                            width: "170px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{ marginLeft: "10px", height: "11.67px" }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "100px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "130px",
                          }}
                        />
                        <span
                          className="loading-span animation"
                          style={{
                            marginLeft: "10px",
                            height: "11.67px",
                            width: "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        height: "63px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ padding: "10px" }}
                        className="loading-content-button animation"
                      />
                      <div
                        style={{ padding: "10px" }}
                        className="loading-content-button animation"
                      />
                    </div>
                    <button
                      className="animation"
                      style={{
                        height: "21px",
                        width: "127px",
                        position: "absolute",
                        top: "-23px",
                        right: 0,
                        borderRadius: "5px 5px 1px 0",
                      }}
                    />
                  </div>
                </div>
              );
            })
          ))}
      </div>
    </>
  );
};

export default RenderTrashCard;
