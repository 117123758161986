import "./TrashCard.css";

import {
  hardDelete,
  hardDeleteassets,
  restoreassetData,
  restoreData,
} from "Config/API/action";
import { useDispatch, useSelector } from "react-redux";
import asserfolderIcon from "Media/icons/blue-folder.jpg";
import AssetIcon from "Media/icons/asset-icon.png";
import { IconButton } from "@mui/material";
import React from "react";
import bFolder from "Media/icons/Folder-Close.png";
import cadIcon from "Media/icons/cad.png";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import { globalOptionButtonAction } from "Redux/action/variable.action";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import options from "Media/icons/feather-more-vertical.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import pptDocument from "Media/icons/pptx.png";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import videoFile from "Media/icons/videoFile.png";
import webLinkImage from "Media/icons/webLinkImage.png";

const DropDownMenu = ({
  getTrashDataHandler,
  id,
  keyValue,
  folderScope,
  assetType,
}) => {
  const token = localStorage.getItem("token");
  console.log(assetType);
  console.log(id);
  return (
    <>
      <div className="dropdown-menu">
        <ul>
          <li
            onClick={() => {
              if (folderScope === "asset") {
                const restore_status = restoreassetData(token, assetType, id);
                restore_status
                  .then((res) => {
                    if (res?.status) {
                      toast(res?.message, {
                        type: "success",
                      });
                      getTrashDataHandler();
                    } else {
                      toast(res?.message, {
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    toast("Failed! Try again later", {
                      type: "error",
                    });
                  });
              } else {
                const restore_status = restoreData(token, id, folderScope);
                restore_status
                  .then((res) => {
                    if (res?.status) {
                      toast(res?.message, {
                        type: "success",
                      });
                      getTrashDataHandler();
                    } else {
                      toast(res?.message, {
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    toast("Failed! Try again later", {
                      type: "error",
                    });
                  });
              }
            }}
          >
            Restore
          </li>
          <li
            onClick={() => {
              if (folderScope == "asset") {
                const delete_status = hardDeleteassets(token, id, assetType);
                delete_status
                  .then((res) => {
                    if (res?.status) {
                      toast(res?.message, {
                        type: "success",
                      });
                      getTrashDataHandler();
                    } else {
                      toast(res?.message, {
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    toast("Failed! Try again later", {
                      type: "error",
                    });
                  });
              } else {
                const delete_status = hardDelete(
                  token,
                  id,
                  keyValue,
                  folderScope
                );
                delete_status
                  .then((res) => {
                    if (res?.status) {
                      toast(res?.message, {
                        type: "success",
                      });
                      getTrashDataHandler();
                    } else {
                      toast(res?.message, {
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    toast("Failed! Try again later", {
                      type: "error",
                    });
                  });
              }
            }}
          >
            Delete
          </li>
        </ul>
      </div>
    </>
  );
};

const TrashCard = ({ getTrashDataHandler, files, assets }) => {
  const isOptions = useSelector((state) => state.globalOptionButtonReducer);
  const role = useSelector((state) => state.authenticationReducer?.role);
  const dispatch = useDispatch();
  console.log(assets);
  // Handle empty or undefined `files` prop
  if (
    files === undefined ||
    files === null ||
    files === "" ||
    files?.length === 0
  ) {
    files = {};
  }

  if (!files) return <p>No Data Found.</p>;

  // Handle the rendering when files are of type "folder"
  if (files?.type?.toString() !== "folder" && !files?.isAsset) {
    let icon = "";
    if (files?.type === "weblink") {
      icon = webLinkImage;
    } else {
      const fileType = files?.path?.split(".").at(-1)?.toLowerCase();

      // Assign appropriate icons based on file type
      switch (fileType) {
        case "png":
        case "jpg":
        case "jpeg":
          icon = imageIcon;
          break;

        case "pdf":
          icon = pdfIcon;
          break;

        case "docx":
        case "doc":
          icon = officeIcon;
          break;

        case "xlsx":
        case "xls":
        case "csv":
          icon = excelIcon;
          break;

        case "ppt":
        case "pptx":
          icon = pptDocument;
          break;

        case "mp4":
        case "mkv":
        case "webm":
          icon = videoFile;
          break;

        case "stp":
        case "step":
        case "dxf":
        case "stl":
          icon = cadIcon;
          break;

        default:
          icon = unknownFile;
      }
    }

    return (
      <>
        <div className="trash-file-container">
          {role?.toString() === "admin" ||
          role?.toString() === "super_admin" ? (
            <IconButton
              className="trash-file-options"
              onClick={() => {
                dispatch(globalOptionButtonAction(files.unique_id));
                if (isOptions?.toString() === files.unique_id?.toString()) {
                  dispatch(globalOptionButtonAction(""));
                }
              }}
            >
              <img src={options} alt="Menu options" />
              {isOptions?.toString() === files.unique_id?.toString() ? (
                <DropDownMenu
                  getTrashDataHandler={getTrashDataHandler}
                  id={files?.unique_id}
                  keyValue={files?.key}
                  folderScope={files?.scope}
                />
              ) : null}
            </IconButton>
          ) : null}

          <div>
            <div className="trash-file-icon">
              <img src={icon} alt="File Icon" />
            </div>
            <div className="trash-file-text">
              <p>{files?.name ? files.name : null}</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Handle rendering when `assets` is passed as a prop
  if (assets?.length > 0) {
    console.log(assets);
    return (
      <div className="trash-file-container">
        {assets.map(
          (asset, index) =>
            asset.isAsset && (
              <div key={asset.key}>
                {/* Check if the user has 'admin' or 'super_admin' role */}
                {role?.toString() === "admin" ||
                role?.toString() === "super_admin" ? (
                  <IconButton
                    className="trash-folder-options"
                    onClick={() => {
                      dispatch(globalOptionButtonAction(asset.unique_id));
                      if (
                        isOptions?.toString() === asset.unique_id?.toString()
                      ) {
                        dispatch(globalOptionButtonAction(""));
                      }
                    }}
                  >
                    <img src={options} alt="Menu options" />
                    {/* Render DropDownMenu if the current asset is selected */}
                    {isOptions?.toString() === asset.unique_id?.toString() ? (
                      <DropDownMenu
                        getTrashDataHandler={getTrashDataHandler}
                        id={asset.unique_id}
                        key={asset.key}
                        folderScope={"asset"}
                        assetType={asset.assetType}
                      />
                    ) : null}
                  </IconButton>
                ) : null}

                <div className="trash-file-icon">
                  {/* Asset Folder Icon */}
                  <img
                    src={asserfolderIcon}
                    alt="Asset Icon"
                    className="trash-asset-icon"
                  />
                  {/* Asset External Icon */}
                  <img
                    src={AssetIcon}
                    alt="External"
                    style={{
                      position: "absolute",
                      left: "60px",
                      bottom: "5px",
                      width: "17.5px",
                    }}
                  />
                </div>

                <div className="trash-file-text">
                  <p>{asset?.name}</p>
                </div>
              </div>
            )
        )}
      </div>
    );
  }

  // Default folder rendering (for when it's a folder)
  return (
    <>
      {Object.keys(files).length?.toString() !== "0" ? (
        <div className="trash-folder-single">
          {role?.toString() === "admin" ||
          role?.toString() === "super_admin" ? (
            <IconButton
              className="trash-folder-options"
              onClick={() => {
                dispatch(globalOptionButtonAction(files.unique_id));
                if (isOptions?.toString() === files.unique_id?.toString()) {
                  dispatch(globalOptionButtonAction(""));
                }
              }}
            >
              <img src={options} alt="Menu options" />
              {isOptions?.toString() === files.unique_id?.toString() ? (
                <DropDownMenu
                  getTrashDataHandler={getTrashDataHandler}
                  id={files.unique_id}
                  key={files.key}
                  folderScope={files?.scope}
                />
              ) : null}
            </IconButton>
          ) : null}
          <div>
            <img src={bFolder} alt="Folder" />
            <p>{files?.name ? files.name : null}</p>
          </div>
        </div>
      ) : (
        <p>No Data Found.</p>
      )}
    </>
  );
};

export default TrashCard;
