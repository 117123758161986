import {
  hardDelete,
  hardDeleteassets,
  restoreassetData,
  restoreData,
} from "Config/API/action";
import { useDispatch, useSelector } from "react-redux";
import "./trashAssetCard.css";
import { IconButton } from "@mui/material";
import asserfolderIcon from "Media/icons/blue-folder.jpg";
import AssetIcon from "Media/icons/asset-icon.png";
import React from "react";

import { globalOptionButtonAction } from "Redux/action/variable.action";

import options from "Media/icons/feather-more-vertical.svg";

import { toast } from "react-toastify";

import profilePic from "Media/blank-profile-picture.png";
const DropDownMenu = ({ getTrashDataHandler, id, folderScope }) => {
  const token = localStorage.getItem("token");

  return (
    <div  className="dropdown-menu">
      <ul>
        <li
          onClick={() => {
            const restore_status = restoreassetData(token, "assets", id);
            restore_status
              .then((res) => {
                if (res?.status) {
                  toast(res?.message, { type: "success" });
                  getTrashDataHandler();
                } else {
                  toast(res?.message, { type: "error" });
                }
              })
              .catch(() => {
                toast("Failed! Try again later", { type: "error" });
              });
          }}
        >
          Restore
        </li>
        <li
          onClick={() => {
            const delete_status = hardDeleteassets(token, id,"assets");
            delete_status
              .then((res) => {
                if (res?.status) {
                  toast(res?.message, { type: "success" });
                  getTrashDataHandler();
                } else {
                  toast(res?.message, { type: "error" });
                }
              })
              .catch(() => {
                toast("Failed! Try again later", { type: "error" });
              });
          }}
        >
          Delete
        </li>
      </ul>
    </div>
  );
};

const TrashAssetCard = ({ getTrashDataHandler, userData }) => {
  const isOptions = useSelector((state) => state.globalOptionButtonReducer);
  const role = useSelector((state) => state.authenticationReducer?.role);
  const dispatch = useDispatch();

  if (!userData) return <p>No Asset Data.</p>;

  const { unique_id, assetsName, assetNumber, assetScope, assetLocation } =
    userData;
  let icon = profilePic; // Placeholder for asset icon

  return (
    <div className="trash-file-container" key={unique_id}>
      {/* Show the IconButton for admin or super_admin */}
      {role === "admin" || role === "super_admin" ? (
        <div
          className={`trash-asset-options  ${
            isOptions === unique_id ? "open" : ""
          }`}
          onClick={() => {
            // Toggle dropdown visibility
            dispatch(globalOptionButtonAction(unique_id));
            if (isOptions === unique_id) {
              dispatch(globalOptionButtonAction("")); // Close the dropdown if it's already open
            }
          }}
        >
          <img src={options} alt="Menu options" />
          {isOptions === unique_id && (
        <DropDownMenu
          getTrashDataHandler={getTrashDataHandler}
          id={unique_id}
          folderScope={assetScope}
        />
      )}
        </div>
      ) : null}

      {/* Asset card content */}
      <div className="trash-asset-card-content">
        <div className="trash-asset-icon-container">
          <img
            src={asserfolderIcon}
            alt="Folder"
            className="trash-asset-icon"
          />
          <img
            src={AssetIcon}
            alt="External"
            style={{
              position: "absolute",
              left: "70px",
              bottom: "16px",
              width: "17.5px",
            }}
          />
        </div>

        <div className=" trash-asset-info">
          <span>{assetsName}</span>
          <i>({assetNumber || ""})</i>
        </div>
      </div>

      {/* Conditionally render the dropdown menu when this card is selected */}
    
    </div>
  );
};

export default TrashAssetCard;
