import React, { useEffect, useRef, useState } from "react";
import "./AssetManagement.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import emtyfolder from "Media/empty-folder.webp";
import { useNavigate } from "react-router-dom";
import {
  deleteAssets,
  getAssets,
  getSharedAssets,
  getUserDetails,
  getAllSubscriptions,
} from "Config/API/get";
import { servicePageTypeAction } from "Redux/action/render.action";
import PurchaseSubscription from "Components/Popup/PurchaseSubscription/PurchaseSubscription";
import { purchaseSubscriptionMessages } from "Constants/Values";
import { purchaseSubscription } from "Config/API/action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { assetuniqueid } from "Redux/action/variable.action";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import options from "Media/icons/feather-more-vertical.svg";
import { IconButton } from "@mui/material";
import PremiumIcon from "Media/premium@2x.png";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
const AssetManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllAvailableSubscriptions();
    getMyAssets();
    getSharedAsset();
    getuserData();

    // eslint-disable-next-line
  }, []);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [MyAssets, setMyAssets] = useState([]);
  const [SharedAssets, setSharedAssets] = useState([]);
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [typingTimer, setTypingTimer] = useState(null);
  
  const [assetUserRole, setAssetUserRole] = useState("");
  
  let doneTypingInterval = 300;
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [serviceTicketSubscription, setServiceTicketSubscription] = useState(
    []
  );
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchasePopup, setShowPurchasePopup] = useState(false);
  const [purchaseType, setPurchaseType] = useState("");
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const getAllAvailableSubscriptions = () => {
    setLoader();
    const response = getAllSubscriptions(token);
    response.then((res) => {
      resetLoader();
      if (res?.data?.status) {
        const filteredSubscription = res.data.data?.filter(
          (item) => item?.subscription_type === "service_ticket"
        );
        setServiceTicketSubscription(filteredSubscription[0]);
        console.log(serviceTicketSubscription)
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };
  const subscribeService = () => {
    const response = purchaseSubscription(
      token,
      serviceTicketSubscription?.subscription_type,
      serviceTicketSubscription?.rate,
      ""
    );
    response.then((res) => {
      setPurchaseLoading(false);
      if (res?.status) {
        if (purchaseSubscriptionMessages?.includes(res?.message?.toLowerCase()))
          return toast(res.message, {
            type: "info",
          });
        else {
          getAllAvailableSubscriptions();
          setShowPurchasePopup(false);
          toast(res.message, {
            type: "success",
          });
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };
  const handleAssetManagementClick = () => {
    if (serviceTicketSubscription?.subscriptionStatus?.toString() === "true") {
      dispatch(servicePageTypeAction("PREMIUM"));
      if (
        loggedInUserDetails?.ticket_external_read_role !== "manager" &&
        loggedInUserDetails?.ticket_internal_read_role !== "manager"
      ) {
        return toast("You do not have access", {
          type: "info",
        });
      }
    } else if (serviceTicketSubscription?.rate?.length > 0) {
      if (loggedInUserDetails?.storage_subscription === "manager") {
        setPurchaseType("Asset Management & Service Ticketing ");
        setShowPurchasePopup(true);
      } else {
        toast("You do not have access", {
          type: "info",
        });
      }
    } else toast("Service not purchasable yet", { type: "info" });
  };
  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
    
      setAssetUserRole(res.data.data.asset_manager)
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const getMyAssets = () => {
    setLoader();
    const response = getAssets(token, searchKeyword);
    response
      .then((res) => {
        resetLoader();
        if (res?.data?.status) {
          const MyAssets = res.data.data || []; // Default to empty array if undefined

          // Ensure MyAssets is an array before sorting
          const sortedAssets = Array.isArray(MyAssets)
            ? MyAssets.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            : [];

          setMyAssets(sortedAssets);
        } else {
          toast("Failed! Try again later", { type: "error" });
        }
      })
      .catch((error) => {
        resetLoader();
        console.error("Error fetching assets:", error);
        toast("Error fetching assets", { type: "error" });
      });
  };
  useEffect(() => {
    const handleTyping = () => {
      clearTimeout(typingTimer);
      const timer = setTimeout(() => {
        getMyAssets();
        getSharedAsset();
      }, doneTypingInterval);
      setTypingTimer(timer);
    };

    handleTyping();

    // Cleanup on unmount
    return () => {
      clearTimeout(typingTimer);
    };
  }, [searchKeyword]);
  const getSharedAsset = () => {
    setLoader();
    const response = getSharedAssets(token, searchKeyword);
    response.then((res) => {
      resetLoader();
      if (res?.data?.status) {
        const SharedAssets = res?.data?.data || []; // Ensure SharedAssets is an array
        const sortedAssets = SharedAssets.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setSharedAssets(sortedAssets);
      
      } else {
       console.log("Failed! Try again later", { type: "error" });
      }
    });
  };
  const handleDelete = (id) => {
    const archiveAsset = deleteAssets(token, id);
    archiveAsset
      .then((res) => {
        toast("Asset archived successfully", {
          type: "success",
        });
        getMyAssets();
        getSharedAsset();
      })
      .catch((err) => {
        resetLoader();
        console.error("Error deleting asset:", err);
        toast("Error deleting asset", {
          type: "error",
        });
      })
      .finally(() => {
        setConfirmPopup(false); // Close the confirmation popup after action
      });
  };
  return (
    <>
      <div className="asset-management-parent">
        <div className="asset-management-header">
          <Headers />
        </div>
        <div className="asset-management-navigation">
          <Navigation />
        </div>
        <div className="asset-management-main">
          <h1>Asset Management</h1>
          <div className="asset-management-main-box">
            <div className="header-bar-asset">
              <div
                className="asset-search asset-management-search"
                style={{ marginBottom: assetUserRole ? "auto" : "20px" }}
              >
                <input
                  type="text"
                  placeholder="Search assets by Asset# /Asset name /Vendor Name"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
                <SearchIcon className="search-icon" />
              </div>
              { assetUserRole&&(
                <div className="createbutton">
                  <button
                    onClick={() => {
                      serviceTicketSubscription?.subscriptionStatus?.toString() ===
                      "true"
                        ? navigate("/create-assets")
                        : handleAssetManagementClick();
                    }}
                    aria-label="Create a new asset"
                    style={{
                      backgroundColor:
                        serviceTicketSubscription?.subscriptionStatus?.toString() ===
                          "false" && "#919191",
                    }}
                  >
                    Create New
                  </button>
                </div>
              )}
            </div>

            <div className="show-asset-boxes">
              <div
                className={`asset-box ${
                  serviceTicketSubscription?.subscriptionStatus?.toString() ===
                  "false"
                    ? "grey-overlay"
                    : ""
                }`}
                onClick={() => {
                  if (serviceTicketSubscription?.subscriptionStatus?.toString() === "false") {
                    handleAssetManagementClick();
                  }
                }}
             
              >
                {serviceTicketSubscription?.subscriptionStatus?.toString() ===
                  "false" && (
                  <img
                    src={PremiumIcon}
                    alt="Premium service"
                    className="hover-image"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                    }}
                  />
                )}

                <div className="asset-box-top">
                  <p>MY ASSETS</p>
                </div>

                <div className="asset-box-wrapper">
                  <div className="assetm-head-row">ASSETS#</div>
                  <div className="assetm-head-row">LOCATION</div>
                  <div className="assetm-head-row">ASSET NAME</div>
                  <div className="assetm-head-row">VENDOR NAME</div>
                </div>

                <div className="asset-box-bottom">
                  {MyAssets?.length > 0 ? (
                    MyAssets.map((asset, index) => (
                      <div className="asset-content-wrapper" key={index}>
                        <div
                          className="asset-content-row"
                          style={{ color: "#4671A4", fontWeight: "bold" }}
                          onClick={() => {
                            if (serviceTicketSubscription?.subscriptionStatus?.toString() ===
                            "true") {
                              dispatch(assetuniqueid(asset?.unique_id));
                              navigate("/asset-details");
                            }
                          }}
                        >
                          {asset?.assetNumber}
                        </div>
                        <div className="asset-content-row">
                          {asset?.assetLocation}
                        </div>
                        <div className="asset-content-row">
                          {asset?.assetsName}
                        </div>
                        <div className="asset-content-row" style={{display:"flex",justifyContent:"space-between"}}>
                          {asset?.vendorName}
                          <IconButton
                          style={{ padding: "0px", margin: "5px" }}
                          onClick={(event) => {
                            if (serviceTicketSubscription?.subscriptionStatus?.toString() ===
                            "true") {
                           
                                setAnchorEl(event.currentTarget);
                                setSelectedAssetId(asset?.unique_id);
                              
                            }
                          }}
                          className="dropdown-styling"
                        >
                          <img
                            src={options}
                            alt="Menu options"
                            style={{ height: "18px" }}
                          />
                        </IconButton>
                        </div>

                        {confirmPopup && (
                          <RenderConfirm
                            message={`Archive it?`}
                            data={selectedAssetId}
                            hide={setConfirmPopup}
                            status={handleDelete}
                          />
                        )}

                        

                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuItem
                            onClick={() => {
                              setConfirmPopup(true);
                              setAnchorEl(null);
                            }}
                            style={{
                              boxShadow: "none",
                              borderRadius: "5px",
                              color: "grey",
                              padding: "4px 10px",
                              fontSize: "12px",
                            }}
                          >
                            Archive
                          </MenuItem>
                        </Menu>
                      </div>
                    ))
                  ) : (
                    <div className="empty-assets-container">
                      <img src={emtyfolder} alt="empty-folder" />
                      <p className="empty-assets">Currently Empty</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="asset-box">
                <div className="asset-box-top">
                  <p>ASSETS SHARED WITH ME</p>
                </div>
                <div className="asset-box-wrapper">
                  <div className="assetm-head-row">ASSETS#</div>
                  <div className="assetm-head-row">LOCATION</div>
                  <div className="assetm-head-row">ASSET NAME</div>
                  <div className="assetm-head-row">VENDOR NAME</div>
                </div>
                <div className="asset-box-bottom">
                  {SharedAssets?.length > 0 ? (
                    SharedAssets.map((asset, index) => (
                      <div className="asset-content-wrapper" key={index}>
                        <div
                          className="asset-content-row"
                          style={{ color: "#4671A4", fontWeight: "bold" }}
                          onClick={() => {
                            dispatch(assetuniqueid(asset?.unique_id));
                            navigate("/asset-details", {
                              state: { isShared: true }
                            });
                          }}
                        >
                          {asset?.assetNumber}
                        </div>
                        <div className="asset-content-row">
                          {asset?.assetLocation}
                        </div>
                        <div className="asset-content-row">
                          {asset?.assetsName}
                        </div>
                        <div className="asset-content-row">
                          {asset?.vendorName}
                          
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="empty-assets-container">
                      <img src={emtyfolder} alt="empty-folder" />
                      <p className="empty-assets">Currently Empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
      {showPurchasePopup && (
        <PurchaseSubscription
          header={purchaseType} 
          description={`This is a Premium Service. You need to purchase it with a charge of ${serviceTicketSubscription?.rate} Credits Per Month.`}
          hide={() => setShowPurchasePopup(false)}
          subscribeService={subscribeService}
          setLoading={setPurchaseLoading}
          loading={purchaseLoading}
        />
      )}
    </>
  );
};

export default AssetManagement;
