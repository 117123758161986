import "./Popup.css";

import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";

const Popup = ({ verify, message, hide }) => {
  const [text, setText] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    verify(text);
    setTimeout(() => {
      hide(false);
    }, 1000);
  };

  return (
    <>
      <nav className="popup-container">
        <div className="popup-popup">
          <div className="popup-cross" onClick={() => hide(false)}>
            X
          </div>
          <span>{message}</span>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="popup-email">
              <TextField
                fullWidth
                required
                value={text}
                label="Name"
                inputProps={{ maxLength: 40 }}
                variant="outlined"
                type="text"
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PostAddIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="popup-submit">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#446fa2", color: "white" }}
              >
                CREATE
              </Button>
            </div>
          </form>
        </div>
      </nav>
    </>
  );
};

export default Popup;
