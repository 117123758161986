import "./Portfolio.css";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  addPortfolioAndWorkbookFiles,
  deletePurchasedSubscription,
  modifyPortfolioAndWorkBook,
  purchaseSubscription,
  replacePortfolioFiles,
  sendSubscriptionRequest,
} from "Config/API/action";
import {
  deletePortfolioAndWorkBook,
  unassignWorkBook,
} from "Config/API/update";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  getAssignedWorkbookData,
  getInternalMembers,
  getPortfolioData,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPortfolioAndWorkBook from "Components/Popup/AddPortfolioAndWorkBook/AddPortfolioAndWorkBook";
import AddWebLink from "Components/Popup/AddWebLink/AddWebLink";
import AssignWorkBookPopup from "Components/Popup/AssignWorkBookPopup/AssignWorkBookPopup";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Headers from "Components/Header/Headers";
import HttpsIcon from "@mui/icons-material/Https";
import IFramePopup from "Components/Popup/IFramePopup/IFramePopup";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ManagePortfolioWorkbookPopup from "Components/Popup/ManagePortfolioWorkbookPopup/ManagePortfolioWorkbookPopup";
import Navigation from "Components/SideNavBar/Navigation";
import PersonIcon from "@mui/icons-material/Person";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PurchaseSubscription from "Components/Popup/PurchaseSubscription/PurchaseSubscription";
import RenamePopup from "Components/Popup/RenamePopup/RenamePopup";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import RenderQRPopup from "Components/Popup/QRcodePopup/Container/RenderQRcodePopup";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import addPortfolio from "Media/icons/addPortfolio.png";
import addWorkBook from "Media/icons/addWorkBook.svg";
import arrowUp from "Media/icons/arrowUp.png";
import blank from "Media/blank-profile-picture.png";
import cadIcon from "Media/icons/cad.png";
import excelDocument from "Media/icons/excelDocument.png";
import externalIcon from "Media/icons/External_icon.svg";
import folderSettings from "Media/icons/ionic-ios-settings.svg";
import imageIcon from "Media/icons/simple-image.png";
import openWorkBook from "Media/icons/openWorkBook.png";
import pdfDocument from "Media/icons/pdfDocument.png";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import pptDocument from "Media/icons/pptx.png";
import { purchaseSubscriptionMessages } from "Constants/Values";
import qrcode from "Media/icons/metro-qrcode.svg";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import uploadFile from "Media/icons/uploadFile.svg";
import useIsMyOrganization from "Hooks/useIsMyOrganization";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import useMediaQuery from "@mui/material/useMediaQuery";
import videoFile from "Media/icons/videoFile.png";
import webLink from "Media/icons/webLink.svg";
import webLinkImage from "Media/icons/webLinkImage.png";
import wordDocument from "Media/icons/wordDocument.png";

const Portfolio = () => {
  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);
  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;

  const qrCodeUrl = fullURL?.replace(
    baseURL,
    `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
  );

  const token = localStorage.getItem("token");
  const { orgId } = useParams();
  const inputFile = useRef(null);
  const replaceFile = useRef(null);
  const anchorTag = useRef(null);
  // const toastId = useRef(null);
  const linkTag = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMyOrganization = useIsMyOrganization();
  const isStorageLimitReached = useIsStorageLimitReached();
  const modifyActionButtonWidth = useMediaQuery("(max-width:600px)");
  const [searchParams, setSearchParams] = useSearchParams();
  const portfolio_role = useSelector(
    (state) => state.authenticationReducer?.portfolio_role
  );
  const workbook_role = useSelector(
    (state) => state.authenticationReducer?.workbook_role
  );
  const loggedInUserId = useSelector(
    (state) => state.authenticationReducer?.id
  );
  const role = useSelector((state) => state.authenticationReducer.role);
  const [orgData, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [showQrPopup, setshowQrPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddPortfolio, setShowAddPortfolio] = useState(false);
  const [showAddWorkbookFIle, setShowAddWorkbookFile] = useState(false);
  const [showAddWebLink, setShowAddWebLink] = useState(false);
  const [showReplaceWebLink, setShowReplaceWebLink] = useState(false);
  const [showRenameItemPopup, setShowRenameItemPopup] = useState(false);
  const [showIFramePopup, setShowIFramePopup] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchasePopup, setShowPurchasePopup] = useState(false);
  const [showAddPortfolioRate, setShowAddPortfolioRate] = useState(false);
  const [showMoreActionButtons, setShowMoreActionButtons] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showUnsubscribeConfirmPopup, setShowUnsubscribeConfirmPopup] =
    useState(false);
  const [unAssignWorkbookData, setUnAssignWorkbookData] = useState({});
  const [
    showUnassignWorkbookConfirmPopup,
    setShowUnassignWorkbookConfirmPopup,
  ] = useState(false);
  const [assignWorkbookSection, setAssignWorkbookSection] = useState(false);
  const [showAssignWorkbookPopup, setShowAssignWorkbookPopup] = useState(false);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("type") || "portfolio"
  );
  const [showMoveFolderPopup, setShowMoveFolderPopup] = useState(false);
  const isConnected = useSelector(
    (state) => state.renderOrganizationInfoReducer?.isConnected
  );
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );

  const [portfolioData, setPortfolioData] = useState([]);
  const [assignedWorkbookData, setAssignedWorkbookData] = useState([]);
  const [assignedFilteredWorkbookData, setAssignedFilteredWorkbookData] =
    useState([]);
  const [internalMembers, setInternalMembers] = useState([]);
  const [bookmarkData, setBookmarkData] = useState([]);
  const [folderKey, setFolderKey] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const loadingArray = ["", "", "", ""];
  const [currentName, setCurrentName] = useState("");
  const [selectedtype, setSelectedType] = useState("");
  const [parentUniqueId, setParentUniqueId] = useState("");
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  // const fileTypeArray = ["doc", "docx", "xls", "xlsx", "csv"];
  const fileTypeArray = [];

  const options = {
    fieldSeparator: ",",
    filename: bookmarkData[bookmarkData?.length - 1]?.name,
    quoteStrings: '"',
    decimalSeparator: ".",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvConfig = mkConfig({ ...options });

  const container = document.getElementById("label-container");
  container?.addEventListener("wheel", function (e) {
    if (e.deltaY > 0) {
      container.scrollLeft += 100;
      e.preventDefault();
      // prevenDefault() will help avoid worrisome
      // inclusion of vertical scroll
    } else {
      container.scrollLeft -= 100;
      e.preventDefault();
    }
  });

  useEffect(() => {
    if (!loading) getPortfolio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getActiveFolderName = () => {
    if (bookmarkData?.length === 0 && selectedTab === "portfolio")
      return { name: "Portfolio Files" };
    if (bookmarkData?.length === 0 && selectedTab === "workbook")
      return { name: "Workbooks" };
    if (bookmarkData?.length > 0)
      return { name: bookmarkData[bookmarkData?.length - 1]?.name };
  };

  const getAssignedWorkbook = () => {
    if (!lazyLoading) setLazyLoading(true);
    const response = getAssignedWorkbookData(
      token,
      searchParams.get("folderId"),
      ""
    );
    response.then((res) => {
      setLoading(false);
      setLazyLoading(false);
      if (res?.data.status) {
        setAssignedWorkbookData(res?.data.data);
        setAssignedFilteredWorkbookData(res?.data.data);
      } else
        toast(res.data.message, {
          type: "error",
        });
    });
  };

  const getInternalMembersData = () => {
    const response = getInternalMembers(token);
    response.then((res) => {
      if (res?.status) {
        setInternalMembers(res?.data.internal);
      } else
        toast(res.data.message, {
          type: "error",
        });
    });
  };

  const getPortfolio = () => {
    if (!loading) setLoading(true);
    setSelectedItem({});
    const response = getPortfolioData(
      token,
      selectedTab,
      searchParams.get("folderId") ? searchParams.get("folderId") : 0,
      role === "super_admin" ? "-1" : orgId
    );
    response.then((res) => {
      if (res.data.status) {
        setSubscriptionDetails(res?.data?.subscriptionDetail);
        if (!orgData.logo) setOrgData(res.data.orgData[0]);
        setFolderKey(
          res.data.bookmarkData[res.data.bookmarkData.length - 1]?.key
        );
        const bookMarkLength = res.data.bookmarkData?.length;
        const bookmarkDataCopy = JSON.parse(
          JSON.stringify(res.data.bookmarkData)
        );
        setBookmarkData(bookmarkDataCopy.splice(1));
        if (
          res.data.bookmarkData[bookMarkLength - 1]?.subType === "workbookFile"
        ) {
          setAssignWorkbookSection(true);
          getAssignedWorkbook();
          getInternalMembersData();
        } else {
          setAssignWorkbookSection(false);
          setLoading(false);
          setLazyLoading(false);
        }
        if (isConnected === "not-connected") {
          const unlockedPortfolioData = res?.data?.data?.filter(
            (item) => item?.scope === "external" && item?.security !== "locked"
          );
          setPortfolioData(unlockedPortfolioData);
        } else {
          setPortfolioData(res?.data?.data);
        }
        switch (res.data.message) {
          case "You can not access workbook of other organization":
            toast(res.data.message, {
              type: "info",
            });
            break;
          case "Not Connected":
            toast(
              `You are not Connected with ${
                res.data.orgData[0]?.organization_name
              } to view its ${
                selectedTab === "portfolio" ? "Portfolio Files" : "Workbooks"
              }`,
              {
                type: "info",
              }
            );
            break;
          default:
            // Do Nothing
            break;
        }
      } else {
        setLoading(false);
        setLazyLoading(false);
        toast(res.data.message, {
          type: "error",
        });
      }
    });
  };

  const changeStatus = (type, value) => {
    setLoading(true);
    let changedValue = value;
    if (value === "locked") changedValue = "unlocked";
    if (value === "unlocked") changedValue = "locked";
    if (value === "external") changedValue = "internal";
    if (value === "internal") changedValue = "external";
    const response = modifyPortfolioAndWorkBook(
      token,
      selectedItem?.id,
      type,
      changedValue
    );
    response.then((res) => {
      if (res.status) {
        toast(res.message, {
          type: "success",
        });
        getPortfolio();
      } else {
        setLoading(false);
        toast(res.message, {
          type: "error",
        });
      }
    });
  };

  const validateStatusChange = (type, value) => {
    if (value === "unlocked" && selectedItem?.rate?.length === 0) {
      if (loggedInUserDetails?.credit_account_manager === "manager")
        return setShowAddPortfolioRate(true);
      return toast(
        "Portfolio rates not available to lock this folder. You don't have permission to set rates",
        { type: "info" }
      );
    }
    changeStatus(type, value);
  };

  const renameItem = (value) => {
    changeStatus("name", value);
  };

  const handleChangeStatus = () => {
    changeStatus("security", "unlocked");
  };

  const goToRootFolder = (value) => {
    setLazyLoading(true);
    setAssignWorkbookSection(false);
    setSelectedTab(value);
    setShowDropdown(false);
    setShowMoreActionButtons(false);
    setSelectedItem({});
    setBookmarkData([]);
    setSearchParams({ type: value });
  };

  const openFile = (item) => {
    if (item?.type === "webLink") return setShowIFramePopup(true);
    localStorage.setItem("filePath", item?.path);

    localStorage.setItem(
      "fileType",
      item?.key?.split(".").pop()?.toLowerCase()
    );
    if (fileTypeArray.includes(item?.key?.split(".").pop()?.toLowerCase()))
      return anchorTag.current.click();
    else linkTag.current.click();
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (showDropdown) return setShowDropdown(false);
    if (showMoreActionButtons) return setShowMoreActionButtons(false);
    if (
      !isMyOrganization(item?.org_id) &&
      item?.security === "locked" &&
      item?.subscriptionStatus === "false"
    ) {
      if (isConnected === "not-connected" || isConnected === "pending")
        return toast(
          "Content not purchasable as you are not connected to " +
            orgData?.organization_name,
          {
            type: "info",
          }
        );
      if (subscriptionDetails?.requestStatus === "not-requested")
        return toast("Please raise a request first", { type: "info" });
      if (subscriptionDetails?.requestStatus === "pending")
        return toast("Subscription request pending", { type: "info" });
      if (item?.rate?.length === 0)
        return toast("Content not purchasable yet", { type: "info" });
      else return setShowPurchasePopup(true);
    }
    if (item?.type === "folder") {
      if (item?.subType === "workbookFile") {
        setLazyLoading(true);
        setAssignWorkbookSection(true);
      }
      return setSearchParams({ type: selectedTab, folderId: item?.unique_id });
    }
    openFile(item);
  };

  const addFile = (value) => {
    setUploadButtonDisable(true);
    if (isStorageLimitReached(value?.size))
      return setShowStorageLimitReachedPopup(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });
    const addFileStatus = addPortfolioAndWorkbookFiles(
      token,
      value,
      selectedTab,
      searchParams.get("folderId"),
      folderKey,
      { current: toastId }
    );
    addFileStatus
      .then((res) => {
        setUploadButtonDisable(false);
        if (res?.status) {
          if (res?.message?.includes("limit"))
            return toast(res?.message, {
              type: "info",
            });
          toast.update(toastId, {
            render: "Uploaded!",
            type: "success",
            autoClose: 4000,
          });
          getPortfolio();
       
        } else {
       
          toast("Failed! Try again later", { type: "error" });
        }
      })
      .catch(() => {
        toast("Failed! Try again later", { type: "error" });
      });
  };

  const replaceFileData = (value) => {
    setUploadButtonDisable(true);
    if (isStorageLimitReached(value?.size))
      return setShowStorageLimitReachedPopup(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });
    const replaceFileStatus = replacePortfolioFiles(
      token,
      selectedItem?.unique_id,
      value,
      { current: toastId }
    );
    replaceFileStatus
      .then((res) => {
        setUploadButtonDisable(false);
        if (res?.status) {
          if (res?.message?.includes("limit"))
            return toast(res?.message, {
              type: "info",
            });
          toast.update(toastId, {
            render: "Uploaded!",
            type: "success",
            autoClose: 4000,
          });
          getPortfolio();
         
        } else {
      
          toast("Failed! Try again later", { type: "error" });
        }
      })
      .catch(() => {
        toast("Failed! Try again later", { type: "error" });
      });
  };

  const deletePortfolio = (id) => {
    setLoading(true);
    setSelectedItem({});
    setShowConfirmPopup(false);
    const deleteStatus = deletePortfolioAndWorkBook(token, id);
    deleteStatus.then((res) => {
      if (res.status) {
        toast(res.message, {
          type: "success",
        });
        getPortfolio();
      } else {
        setLoading(false);
        toast(res.message, {
          type: "error",
        });
      }
    });
  };

  const renderItemImage = (item) => {
    if (item?.type === "folder") {
      if (item?.subType === "workbookFile")
        return (
          <img
            src={openWorkBook}
            className="portfolio-file-image"
            alt="files"
          />
        );
      else return <img src={portfolioFiles} alt="files" />;
    }
    if (item?.type === "webLink")
      return (
        <img className="portfolio-file-image" src={webLinkImage} alt="files" />
      );
    const fileType = item?.key?.split(".").pop()?.toLowerCase();
    switch (fileType) {
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <img
            src={imageIcon}
            style={{ borderRadius: "4px" }}
            className="portfolio-file-image"
            alt="files"
          />
        );
      case "doc":
      case "docx":
        return (
          <img
            src={wordDocument}
            className="portfolio-file-image"
            alt="files"
          />
        );
      case "xls":
      case "xlsx":
      case "csv":
        return (
          <img
            src={excelDocument}
            className="portfolio-file-image"
            alt="files"
          />
        );
      case "pdf":
        return (
          <img src={pdfDocument} className="portfolio-file-image" alt="files" />
        );
      case "ppt":
      case "pptx":
        return (
          <img src={pptDocument} className="portfolio-file-image" alt="files" />
        );
      case "mp4":
      case "mkv":
      case "webm":
        return (
          <img src={videoFile} className="portfolio-file-image" alt="files" />
        );
      case "stp":
      case "step":
      case "dxf":
      case "stl":
        return (
          <img src={cadIcon} className="portfolio-file-image" alt="files" />
        );
      default:
        return (
          <img src={unknownFile} className="portfolio-file-image" alt="files" />
        );
    }
  };

  const renderItemName = (item) => {
    return item?.name.substr(0, 17);
  };

  const showButton = (from) => {
    if (assignWorkbookSection && from === "bottom") return false;
    else if (selectedTab === "portfolio" && portfolio_role === "manager")
      return true;
    else if (selectedTab === "workbook" && workbook_role === "manager")
      return true;
    else return false;
  };

  const subscribeOrganization = () => {
    const response = purchaseSubscription(
      token,
      "portfolio",
      selectedItem?.rate,
      orgId
    );
    response.then((res) => {
      setPurchaseLoading(false);
      if (res.status) {
        if (purchaseSubscriptionMessages?.includes(res?.message?.toLowerCase()))
          return toast(res.message, {
            type: "info",
          });
        else {
          getPortfolio();
          setShowPurchasePopup(false);
          toast(res.message, {
            type: "success",
          });
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const renderWorkbookCompletionColumn = (
    completionDate,
    completedFilesNumber
  ) => {
    if (completionDate?.length > 0) return completionDate;
    if (completedFilesNumber === 0)
      return `Not Started (0/${portfolioData?.length})`;
    if (completedFilesNumber > 0)
      return `In Progress (${completedFilesNumber}/${portfolioData?.length})`;
  };

  const exportCSV = () => {
    // Cherry Picking only requird Objects to be shown in CSV
    const array = assignedWorkbookData?.map((item) => {
      return {
        "Assign To": item["assignTo"],
        "Assign By": item["assignBy"],
        "Date Assigned": item["dateAssigned"],
        "Date Completed": renderWorkbookCompletionColumn(
          item["completionDate"],
          item["completeNoOfFiles"]
        ),
      };
    });
    const csv = generateCsv(csvConfig)(array);
    download(csvConfig)(csv);
  };

  const handleSearchNameChange = (e) => {
    if (e.target.value.length === 0)
      return setAssignedWorkbookData(assignedFilteredWorkbookData);
    const filteredData = assignedFilteredWorkbookData?.filter(
      (item) =>
        item?.assignTo?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item?.assignBy?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setAssignedWorkbookData(filteredData);
  };

  const handelDeleteSubscription = (subscriptionId) => {
    const response = deletePurchasedSubscription(token, subscriptionId);
    response.then((res) => {
      if (res.status) {
        setSelectedItem({});
        getPortfolio();
        toast(res.message, {
          type: "success",
        });
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const handleSendSubscriptionRequest = () => {
    setLoading(true);
    const response = sendSubscriptionRequest(token, "portfolio", orgId);
    response.then((res) => {
      if (res.status) {
        getPortfolio();
        toast(res?.data?.message, {
          type: "success",
        });
      } else {
        setLoading(false);
        toast(res?.data?.message, {
          type: "error",
        });
      }
    });
  };

  const handleUnassignWorkbook = (data) => {
    setLoading(true);
    const response = unassignWorkBook(
      token,
      data?.parentUniqueId,
      data?.assignTo
    );
    response.then((res) => {
      setLoading(false);
      if (res?.status) {
        toast(res?.message, { type: "success" });
        getAssignedWorkbook();
      } else toast(res?.message, { type: "error" });
    });
  };

  return (
    <>
      <div
        className="portfolio-parent"
        onClick={() => {
          if (showDropdown) return setShowDropdown(false);
          if (showMoreActionButtons) return setShowMoreActionButtons(false);
        }}
      >
        <div className="portfolio-header">
          <Headers />
        </div>

        <div className="portfolio-navigation">
          <Navigation />
        </div>

        <div className="portfolio-main">
          <div
            className="portfolio-wrapper"
            style={{ borderRadius: "5px 5px 0px 0px" }}
          >
            <div className="portfolio-wrapper-header">
              <div className="portfolio-wrapper-header-left-side">
                {!loading && !lazyLoading ? (
                  <>
                    <img
                      src={orgData?.logo ? orgData?.logo : blank}
                      alt="logo"
                    />
                    <span style={{ textTransform: "uppercase" }}>
                      {orgData?.organization_name}
                    </span>
                  </>
                ) : (
                  <>
                    <div className="loading-image animation" />
                    <span className="loading-span animation"></span>
                  </>
                )}
              </div>
              <div className="portfolio-wrapper-header-right-side">
                {!loading && !lazyLoading ? (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        background:
                          selectedTab === "workbook"
                            ? "#446FA2"
                            : "rgb(92, 161, 39)",
                      }}
                      onClick={() => {
                        if (
                          bookmarkData.length === 0 &&
                          selectedTab === "portfolio"
                        )
                          return;
                        goToRootFolder("portfolio");
                      }}
                    >
                      Portfolio Files
                    </Button>
                    {isMyOrganization(orgId) ? (
                      <Button
                        variant="contained"
                        style={{
                          background:
                            selectedTab === "workbook"
                              ? "rgb(92, 161, 39)"
                              : "#446FA2",
                        }}
                        onClick={() => {
                          if (
                            bookmarkData.length === 0 &&
                            selectedTab === "workbook"
                          )
                            return;
                          goToRootFolder("workbook");
                        }}
                      >
                        Workbooks
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          background: "white",
                          width: "max-content",
                          color: "#5CA127",
                          paddingLeft: "0px",
                          gap: "10px",
                          border: "2px solid #5CA127",
                          borderTopLeftRadius: "30px",
                          borderBottomLeftRadius: "30px",
                        }}
                        onClick={() => {
                          navigate("/organization", {
                            state: { data: orgId },
                          });
                          dispatch(renderOrganizationInfo({}));
                        }}
                      >
                        <img
                          src={orgData?.logo ? orgData?.logo : blank}
                          alt="logo"
                          style={{
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            objectFit: "contain",
                          }}
                        />
                        <span>VISIT PAGE</span>
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button className="animation" variant="contained"></Button>
                    {isMyOrganization(orgId) ? (
                      <Button
                        className="animation"
                        variant="contained"
                      ></Button>
                    ) : (
                      <Button
                        className="animation"
                        variant="contained"
                        style={{
                          width: "130px",
                          borderTopLeftRadius: "30px",
                          borderBottomLeftRadius: "30px",
                        }}
                      ></Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="portfolio-label" id="label-container">
            {!loading && !lazyLoading ? (
              <>
                <button
                  onClick={() => {
                    if (bookmarkData.length === 0) return;
                    goToRootFolder(selectedTab);
                  }}
                >
                  {selectedTab === "workbook" ? "Workbooks" : "Portfolio Files"}
                </button>
                {bookmarkData.map((item) => {
                  return (
                    <>
                      {">"}
                      <button
                        onClick={() => {
                          if (
                            item?.unique_id !== searchParams.get("folderId")
                          ) {
                            if (selectedTab === "workbook") {
                              setAssignWorkbookSection(false);
                              setLazyLoading(true);
                            }
                            setSearchParams({
                              type: selectedTab,
                              folderId: item?.unique_id,
                            });
                          }
                        }}
                      >
                        {renderItemName(item)}
                      </button>
                    </>
                  );
                })}
              </>
            ) : (
              <div
                className="animation"
                style={{ height: "20px", width: "50%", maxWidth: "250px" }}
              ></div>
            )}
          </div>
          <div className="portfolio-bottom-wrapper">
            {!assignWorkbookSection ? (
              <div className="portfolio-content">
                {!loading && !lazyLoading ? (
                  portfolioData.length > 0 ? (
                    portfolioData.map((item, index) => {
                      return (
                        <div
                          key={item?.id}
                          className={`portfolio-content-item ${
                            !isMyOrganization(item?.org_id) &&
                            item?.security === "locked" &&
                            item?.subscriptionStatus === "false" &&
                            "disabled"
                          }`}
                          onClick={() => handleItemClick(item)}
                        >
                          <div>
                            {renderItemImage(item)}
                            {item?.scope === "external" &&
                              bookmarkData?.length === 0 && (
                                <div className="portfolio-external-icon">
                                  <img
                                    style={{ height: "18px", width: "18px" }}
                                    src={externalIcon}
                                    alt="externalIcon"
                                  />
                                </div>
                              )}
                            <span>{renderItemName(item)}</span>
                          </div>
                          {isMyOrganization(orgId) && showButton() && (
                            <div style={{ position: "relative" }}>
                              <input
                                type="file"
                                id="replaceFile"
                                hidden
                                ref={replaceFile}
                                onChange={(e) =>
                                  replaceFileData(e.target.files[0])
                                }
                              />
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedItem(item);
                                  setShowMoreActionButtons(false);
                                  if (
                                    selectedItem?.unique_id !==
                                      item?.unique_id &&
                                    showDropdown
                                  )
                                    return;
                                  setShowDropdown(!showDropdown);
                                }}
                              >
                                <MoreVertIcon
                                  style={{ color: "#51595e" }}
                                  fontSize="medium"
                                />
                              </IconButton>
                              {showDropdown &&
                                selectedItem?.unique_id === item?.unique_id && (
                                  <div className="portfolio-dropdown">
                                    {selectedTab === "portfolio" &&
                                      bookmarkData?.length === 0 && (
                                        <>
                                          {item?.scope === "external" && (
                                            <>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  validateStatusChange(
                                                    "security",
                                                    item?.security
                                                  );
                                                  setShowDropdown(false);
                                                }}
                                              >
                                                {item?.security === "locked"
                                                  ? "Remove Lock"
                                                  : "Lock Folder"}
                                              </button>
                                              <div className="portfolio-divider" />
                                            </>
                                          )}
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              changeStatus(
                                                "scope",
                                                item?.scope
                                              );
                                              setShowDropdown(false);
                                              setSelectedItem({});
                                            }}
                                          >
                                            {item?.scope === "external"
                                              ? "Mark As Internal"
                                              : "Mark As External"}
                                          </button>
                                          <div className="portfolio-divider" />
                                        </>
                                      )}
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowRenameItemPopup(true);
                                        setShowDropdown(false);
                                        setCurrentName(item?.name);
                                      }}
                                    >
                                      Rename
                                    </button>
                                    <div className="portfolio-divider" />
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowConfirmPopup(true);
                                        setShowDropdown(false);
                                      }}
                                    >
                                      Delete
                                    </button>
                                    {item.classify === "portfolio" ? (
                                      <>
                                        <div className="portfolio-divider" />
                                        <button
                                          onClick={(e) => {
                                            console.log(
                                              "1111",
                                              "clicked:  item?.type",
                                              item?.type
                                            );
                                            setShowMoveFolderPopup(true);
                                            setSelectedFolderId(
                                              item?.unique_id
                                            );
                                            setSelectedType(item?.type);
                                            setParentUniqueId(
                                              item?.parent_unique_id
                                            );
                                          }}
                                        >
                                          Move
                                        </button>
                                      </>
                                    ) : (
                                      item.parent_unique_id !== "0" && (
                                        <>
                                          <div className="portfolio-divider" />
                                          <button
                                            onClick={(e) => {
                                              setShowMoveFolderPopup(true);
                                              setSelectedFolderId(
                                                item?.unique_id
                                              );
                                            }}
                                          >
                                            Move
                                          </button>
                                        </>
                                      )
                                    )}
                                    {item?.classify === "portfolio" &&
                                      item?.type !== "folder" && (
                                        <>
                                          <div className="portfolio-divider" />
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (item?.type === "file") {
                                                replaceFile.current.value =
                                                  null;
                                                replaceFile.current.click();
                                              } else {
                                                setShowReplaceWebLink(true);
                                              }
                                            }}
                                          >
                                            Replace
                                          </button>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                          )}
                          {item?.security === "locked" &&
                            bookmarkData?.length === 0 && (
                              <div className="portfolio-lock-icon">
                                <HttpsIcon
                                  style={{
                                    color: "white",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="portfolio-no-data">No Data Found</div>
                  )
                ) : (
                  loadingArray.map((item) => {
                    return (
                      <div
                        className="portfolio-content-item"
                        style={{ borderColor: "#ececec" }}
                      >
                        <div>
                          <div className="loading-content-image animation" />
                          <span
                            className="loading-span animation"
                            style={{ marginLeft: "10px" }}
                          ></span>
                        </div>
                        {isMyOrganization(orgId) && showButton() && (
                          <div className="loading-content-button animation" />
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            ) : (
              <div className="portfolio-assign-workbook-content">
                {!loading && !lazyLoading ? (
                  <>
                    {isMyOrganization(orgId) && showButton() && (
                      <header>
                        <Button
                          variant="contained"
                          style={{
                            background: "rgb(92, 161, 39)",
                          }}
                          onClick={() =>
                            navigate("/workbook-editing", {
                              state: {
                                data: bookmarkData[bookmarkData?.length - 1],
                              },
                            })
                          }
                        >
                          <EditIcon
                            style={{ fontSize: "17px", marginTop: "-3px" }}
                          />
                          EDIT
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            background: "rgb(92, 161, 39)",
                          }}
                          onClick={() => {
                            if (portfolioData?.length === 0) {
                              return toast(
                                "Please add atleast 1 file to workbook before assigning",
                                {
                                  type: "info",
                                }
                              );
                            }
                            setShowAssignWorkbookPopup(true);
                          }}
                        >
                          <PersonIcon
                            style={{ fontSize: "17px", marginTop: "-3px" }}
                          />
                          ASSIGN
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            background: "rgb(92, 161, 39)",
                          }}
                          onClick={() => exportCSV()}
                          disabled={assignedWorkbookData?.length === 0}
                        >
                          <ExitToAppIcon
                            style={{ fontSize: "17px", marginTop: "-3px" }}
                          />
                          EXPORT
                        </Button>
                      </header>
                    )}
                    <section>
                      <div className="portfolio-assign-workbook-content-search-section">
                        <input
                          type="search"
                          placeholder="Search name"
                          onChange={(e) => handleSearchNameChange(e)}
                        />
                        {assignedFilteredWorkbookData
                          // using + to change string to number
                          ?.map((item) => +item?.assign_to)
                          .includes(loggedInUserId) && (
                          <Button
                            variant="contained"
                            style={{
                              background: "rgb(92, 161, 39)",
                            }}
                            onClick={() => {
                              navigate("/workbook-files", {
                                state: {
                                  unique_id: searchParams.get("folderId"),
                                  workbookName:
                                    bookmarkData[bookmarkData?.length - 1]
                                      ?.name,
                                },
                              });
                            }}
                            disabled={portfolioData?.length === 0}
                          >
                            <PlayArrowIcon
                              style={{ fontSize: "22px", marginTop: "-3px" }}
                            />
                            Start
                          </Button>
                        )}
                      </div>
                      <div className="portfolio-assign-workbook-content-table">
                        <div className="portfolio-assign-workbook-content-table-row-wrapper">
                          <div
                            className="portfolio-assign-workbook-content-table-head-row"
                            style={{
                              width:
                                isMyOrganization(orgId) && showButton()
                                  ? "23%"
                                  : "25%",
                            }}
                          >
                            ASSIGNED TO
                          </div>
                          <div
                            className="portfolio-assign-workbook-content-table-head-row"
                            style={{
                              width:
                                isMyOrganization(orgId) && showButton()
                                  ? "23%"
                                  : "25%",
                            }}
                          >
                            ASSIGNED BY
                          </div>
                          <div
                            className="portfolio-assign-workbook-content-table-head-row"
                            style={{
                              width:
                                isMyOrganization(orgId) && showButton()
                                  ? "23%"
                                  : "25%",
                            }}
                          >
                            DATE ASSIGNED
                          </div>
                          <div
                            className="portfolio-assign-workbook-content-table-head-row"
                            style={{
                              width:
                                isMyOrganization(orgId) && showButton()
                                  ? "23%"
                                  : "25%",
                            }}
                          >
                            DATE COMPLETED
                          </div>
                        </div>
                        <div className="portfolio-assign-workbook-content-table-bottom">
                          {assignedWorkbookData?.length > 0 ? (
                            assignedWorkbookData?.map((item, index) => {
                              return (
                                <div
                                  className="portfolio-assign-workbook-table-content-wrapper"
                                  key={index}
                                >
                                  <div
                                    className="portfolio-assign-workbook-table-content-row"
                                    style={{
                                      width:
                                        isMyOrganization(orgId) && showButton()
                                          ? "23%"
                                          : "25%",
                                    }}
                                  >
                                    {item?.assignTo}
                                  </div>
                                  <div
                                    className="portfolio-assign-workbook-table-content-row"
                                    style={{
                                      width:
                                        isMyOrganization(orgId) && showButton()
                                          ? "23%"
                                          : "25%",
                                    }}
                                  >
                                    {item?.assignBy}
                                  </div>
                                  <div
                                    className="portfolio-assign-workbook-table-content-row"
                                    style={{
                                      width:
                                        isMyOrganization(orgId) && showButton()
                                          ? "23%"
                                          : "25%",
                                    }}
                                  >
                                    {item?.dateAssigned}
                                  </div>
                                  <div
                                    className="portfolio-assign-workbook-table-content-row"
                                    style={{
                                      color:
                                        item?.completionDate?.length > 0
                                          ? "rgb(92, 161, 39)"
                                          : item?.completeNoOfFiles === 0
                                          ? "#FF0000"
                                          : "#E3B600",
                                      width:
                                        isMyOrganization(orgId) && showButton()
                                          ? "23%"
                                          : "25%",
                                    }}
                                  >
                                    {renderWorkbookCompletionColumn(
                                      item?.completionDate,
                                      item?.completeNoOfFiles
                                    )}
                                  </div>
                                  {isMyOrganization(orgId) && showButton() && (
                                    <div
                                      className="portfolio-assign-workbook-table-content-row"
                                      style={{ width: "8%" }}
                                    >
                                      <button
                                        onClick={() => {
                                          setUnAssignWorkbookData({
                                            parentUniqueId:
                                              item?.parent_unique_id,
                                            assignTo: item?.assign_to,
                                          });
                                          setShowUnassignWorkbookConfirmPopup(
                                            true
                                          );
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{ color: "red" }}
                                          fontSize="small"
                                        />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div className="portfolio-assign-workbook-content-table-msg">
                              No Assignee Found
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <>
                    {isMyOrganization(orgId) && showButton() && (
                      <header>
                        <Button className="animation" />
                        <Button className="animation" />
                        <Button className="animation" />
                      </header>
                    )}
                    <section>
                      <div className="portfolio-assign-workbook-content-search-section">
                        <input
                          className="animation"
                          style={{ borderWidth: 0 }}
                        />
                        <Button className="animation" />
                      </div>
                      <div
                        className="animation"
                        style={{ width: "100%", height: "200px" }}
                      />
                    </section>
                  </>
                )}
              </div>
            )}
            {isMyOrganization(orgId)
              ? showButton("bottom") && (
                  <div className="portfolio-actions">
                    <input
                      type="file"
                      id="uploadFile"
                      hidden
                      ref={inputFile}
                      onChange={(e) => addFile(e.target.files[0])}
                    />
                    <button
                      onClick={() => {
                        if (loading || lazyLoading) return;
                        setshowQrPopup(true);
                        setShowDropdown(false);
                        setSelectedItem({});
                      }}
                    >
                      <img
                        src={qrcode}
                        style={{ height: "21px", width: "21px" }}
                        alt="qrCode"
                      />
                      <p>Create Folder QR Code</p>
                    </button>
                    <button
                      onClick={() => {
                        if (loading || lazyLoading) return;
                        setShowDropdown(false);
                        setSelectedItem({});
                        setShowAddPortfolio(true);
                      }}
                    >
                      <img
                        src={addPortfolio}
                        style={{ height: "26px", width: "26px" }}
                        alt="addData"
                      />
                      <p style={{ textTransform: "capitalize" }}>
                        {`Add ${selectedTab}`}
                        {selectedTab === "workbook" && " Folder"}
                      </p>
                    </button>
                    {bookmarkData?.length > 0 &&
                      selectedTab === "workbook" &&
                      !modifyActionButtonWidth && (
                        <button
                          onClick={() => {
                            if (loading || lazyLoading) return;
                            setShowDropdown(false);
                            setSelectedItem({});
                            setShowAddWorkbookFile(true);
                          }}
                        >
                          <img
                            src={addWorkBook}
                            style={{ height: "26px", width: "26px" }}
                            alt="addData"
                          />
                          <p style={{ textTransform: "capitalize" }}>
                            {`Add ${selectedTab}`}
                          </p>
                        </button>
                      )}
                    {bookmarkData?.length > 0 &&
                      selectedTab === "portfolio" &&
                      !modifyActionButtonWidth && (
                        <>
                          <button
                            disabled={uploadButtonDisable}
                            onClick={() => {
                              if (loading || lazyLoading) return;
                              inputFile.current.value = null;
                              inputFile.current.click();
                            }}
                            style={{
                              pointerEvents: uploadButtonDisable && "none",
                            }}
                          >
                            <img
                              src={uploadFile}
                              style={{ height: "21px", width: "21px" }}
                              alt="uploadFile"
                            />
                            <p style={{ textTransform: "capitalize" }}>
                              Upload File
                            </p>
                          </button>
                          <button
                            onClick={() => {
                              if (loading || lazyLoading) return;
                              setShowAddWebLink(true);
                            }}
                          >
                            <img
                              src={webLink}
                              style={{ height: "21px", width: "21px" }}
                              alt="addWebLink"
                            />
                            <p style={{ textTransform: "capitalize" }}>
                              Add Web Link
                            </p>
                          </button>
                        </>
                      )}
                    {(!modifyActionButtonWidth ||
                      bookmarkData?.length === 0) && (
                      <button
                        onClick={() => {
                          if (loading || lazyLoading) return;
                          setShowDropdown(false);
                          setSelectedItem({});
                          navigate("/service-ticket-user-management", {
                            state: { tab: "portfolioAndWorkbook" },
                          });
                        }}
                      >
                        <img
                          src={folderSettings}
                          style={{ height: "21px", width: "21px" }}
                          alt="rights"
                        />
                        <p>Manage Rights</p>
                      </button>
                    )}
                    {bookmarkData?.length > 0 && modifyActionButtonWidth && (
                      <div style={{ position: "relative" }}>
                        <button
                          style={{ width: "100%" }}
                          onClick={() =>
                            setShowMoreActionButtons(!showMoreActionButtons)
                          }
                        >
                          <img
                            src={arrowUp}
                            style={{ height: "21px", width: "21px" }}
                            alt="rights"
                          />
                          <p>More Actions</p>
                        </button>
                        {showMoreActionButtons && (
                          <div className="portfolio-more-action-buttons">
                            {selectedTab === "portfolio" && (
                              <>
                                <button
                                  disabled={uploadButtonDisable}
                                  onClick={() => {
                                    if (loading || lazyLoading) return;
                                    setShowMoreActionButtons(false);
                                    inputFile.current.value = null;
                                    inputFile.current.click();
                                  }}
                                  style={{
                                    pointerEvents:
                                      uploadButtonDisable && "none",
                                  }}
                                >
                                  <img
                                    src={uploadFile}
                                    style={{ height: "21px", width: "21px" }}
                                    alt="uploadFile"
                                  />
                                  <p style={{ textTransform: "capitalize" }}>
                                    Upload File
                                  </p>
                                </button>
                                <button
                                  onClick={() => {
                                    if (loading || lazyLoading) return;
                                    setShowMoreActionButtons(false);
                                    setShowAddWebLink(true);
                                  }}
                                >
                                  <img
                                    src={webLink}
                                    style={{ height: "21px", width: "21px" }}
                                    alt="addWebLink"
                                  />
                                  <p style={{ textTransform: "capitalize" }}>
                                    Add Web Link
                                  </p>
                                </button>
                              </>
                            )}
                            {selectedTab === "workbook" && (
                              <button
                                onClick={() => {
                                  if (loading || lazyLoading) return;
                                  setShowDropdown(false);
                                  setSelectedItem({});
                                  setShowAddWorkbookFile(true);
                                }}
                              >
                                <img
                                  src={addWorkBook}
                                  style={{ height: "26px", width: "26px" }}
                                  alt="addData"
                                />
                                <p style={{ textTransform: "capitalize" }}>
                                  {`Add ${selectedTab}`}
                                </p>
                              </button>
                            )}
                            <button
                              onClick={() => {
                                if (loading || lazyLoading) return;
                                setShowDropdown(false);
                                setSelectedItem({});
                                navigate("/service-ticket-user-management", {
                                  state: { tab: "portfolioAndWorkbook" },
                                });
                              }}
                            >
                              <img
                                src={folderSettings}
                                style={{ height: "21px", width: "21px" }}
                                alt="rights"
                              />
                              <p>Manage Rights</p>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              : bookmarkData?.length === 0 &&
                isConnected === "connected" &&
                loggedInUserDetails?.portfolio_subscription === "manager" && (
                  <div className="portfolio-actions-other-org">
                    {subscriptionDetails?.requestStatus === "not-requested" ? (
                      <button
                        onClick={() => {
                          if (loading || lazyLoading) return;
                          handleSendSubscriptionRequest();
                        }}
                      >
                        <AddCircleOutlineIcon
                          style={{ color: "white" }}
                          fontSize="small"
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            color: "white",
                          }}
                        >
                          Send Request
                        </p>
                      </button>
                    ) : subscriptionDetails?.requestStatus === "pending" ? (
                      <div className="portfolio-actions-other-org-pending">
                        <button style={{ cursor: "default" }}>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "13px",
                              color: "white",
                            }}
                          >
                            Request Pending
                          </p>
                        </button>
                      </div>
                    ) : (
                      subscriptionDetails?.requestStatus === "approved" &&
                      (subscriptionDetails?.isSubscribed === "true" ? (
                        <button
                          onClick={() => {
                            if (loading || lazyLoading) return;
                            setSelectedItem(portfolioData[0]);
                            setShowUnsubscribeConfirmPopup(true);
                          }}
                        >
                          <DeleteIcon
                            style={{ color: "white" }}
                            fontSize="small"
                          />
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "13px",
                              color: "white",
                            }}
                          >
                            Unsubscribe
                          </p>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (loading || lazyLoading) return;
                            if (
                              portfolioData?.length === 0 ||
                              portfolioData[0]?.rate?.length === 0
                            )
                              return toast("Content not purchasable yet", {
                                type: "info",
                              });
                            setSelectedItem(portfolioData[0]);
                            setShowPurchasePopup(true);
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{ color: "white" }}
                            fontSize="small"
                          />
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "13px",
                              color: "white",
                            }}
                          >
                            Subscribe
                          </p>
                        </button>
                      ))
                    )}
                  </div>
                )}
          </div>
          <a
            ref={anchorTag}
            href={`https://view.officeapps.live.com/op/embed.aspx?src=${localStorage.getItem(
              "filePath"
            )}`}
            target={"_blank"}
            rel="noreferrer"
            style={{ display: "none" }}
          >
            Open
          </a>
          <Link
            ref={linkTag}
            to={`/file-explorer/file-viewer`}
            target={"_blank"}
            style={{ display: "none" }}
          >
            Open
          </Link>
        </div>
      </div>
      {showAddPortfolio && (
        <AddPortfolioAndWorkBook
          hide={setShowAddPortfolio}
          classify={selectedTab}
          getPortfolio={getPortfolio}
          portfolioId={searchParams.get("folderId")}
          folderKey={folderKey}
          navigate={navigate}
          bookmarkLength={bookmarkData?.length}
          subType=""
        />
      )}
      {showAddWorkbookFIle && (
        <AddPortfolioAndWorkBook
          hide={setShowAddWorkbookFile}
          classify={selectedTab}
          getPortfolio={getPortfolio}
          portfolioId={searchParams.get("folderId")}
          folderKey={folderKey}
          navigate={navigate}
          bookmarkLength={bookmarkData?.length}
          subType="workbookFile"
        />
      )}
      {showAddWebLink && (
        <AddWebLink
          hide={setShowAddWebLink}
          classify={selectedTab}
          getPortfolio={getPortfolio}
          parent_unique_id={searchParams.get("folderId")}
          folderKey={folderKey}
        />
      )}
      {showReplaceWebLink && (
        <AddWebLink
          hide={setShowReplaceWebLink}
          classify={selectedTab}
          getPortfolio={getPortfolio}
          parent_unique_id={searchParams.get("folderId")}
          folderKey={folderKey}
          replaceWeblinkData={true}
          oldLink={selectedItem?.path}
          oldLinkName={selectedItem?.name}
          uniqueId={selectedItem?.unique_id}
        />
      )}
      {showQrPopup && (
        <RenderQRPopup
          selectedOrgLogo={""}
          folderData={getActiveFolderName()}
          internalFolder={{}}
          hide={setshowQrPopup}
          path={qrCodeUrl}
        />
      )}
      {showRenameItemPopup && (
        <RenamePopup
          hide={setShowRenameItemPopup}
          type="rename"
          verify={renameItem}
          currentName={currentName}
        />
      )}
      {showPurchasePopup && (
        <PurchaseSubscription
          header="Unlock Content"
          description={`Subscribe to get access to all our locked content for ${selectedItem?.rate} credits/month.`}
          hide={setShowPurchasePopup}
          subscribeService={subscribeOrganization}
          setLoading={setPurchaseLoading}
          loading={purchaseLoading}
        />
      )}
      {showAddPortfolioRate && (
        <VideoCallingRates
          hide={setShowAddPortfolioRate}
          rateType="portfolio"
          ratesData=""
          message="You are Setting Portfolio Rates"
          getAllSubscriptionsRates={handleChangeStatus}
        />
      )}
      {showConfirmPopup && (
        <RenderConfirm
          message="delete this ?"
          data={selectedItem?.id}
          status={deletePortfolio}
          hide={setShowConfirmPopup}
        />
      )}
      {showUnsubscribeConfirmPopup && (
        <RenderConfirm
          message="unsubscribe this Service?"
          data={subscriptionDetails?.subscriptionId}
          status={handelDeleteSubscription}
          hide={setShowUnsubscribeConfirmPopup}
        />
      )}
      {showUnassignWorkbookConfirmPopup && (
        <RenderConfirm
          message="unassign the workbook?"
          data={unAssignWorkbookData}
          status={handleUnassignWorkbook}
          hide={setShowUnassignWorkbookConfirmPopup}
        />
      )}
      {showAssignWorkbookPopup && (
        <AssignWorkBookPopup
          hide={setShowAssignWorkbookPopup}
          allAssigneeId={assignedFilteredWorkbookData?.map(
            (item) =>
              // converting to no using +
              +item?.assign_to
          )}
          headerText={`Assign ${bookmarkData[bookmarkData?.length - 1]?.name}`}
          internalMembers={internalMembers}
          token={token}
          parent_unique_id={searchParams.get("folderId")}
          conn_id={orgId}
          onSuccessCallback={getAssignedWorkbook}
        />
      )}

      {showIFramePopup && (
        <IFramePopup
          hide={setShowIFramePopup}
          filePath={selectedItem?.path}
          org_id={selectedItem?.org_id}
        />
        //added org_id
      )}
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {showMoveFolderPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {showMoveFolderPopup && (
        <ManagePortfolioWorkbookPopup
          hide={setShowMoveFolderPopup}
          selectedFolderId={selectedFolderId}
          token={token}
          bookmarkData={bookmarkData}
          selectedTab={selectedTab}
          portfolio={portfolioData}
          orgId={orgId}
          selectedtype={selectedtype}
          parentUniqueId={parentUniqueId}
        />
      )}
    </>
  );
};

export default Portfolio;
