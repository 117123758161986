import "./OrganizationStorageDashboard.css";

import { Button, useMediaQuery } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import React, { useEffect, useState } from "react";

import { ConvertKiloByteToGigaByte } from "Constants/Functions";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import PurchaseStoragePlanPopup from "Components/Popup/PurchaseStoragePlanPopup/PurchaseStoragePlanPopup";
import { getCreditCardDetails } from "Config/API/get";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const OrganizationStorageDashboard = () => {
  const { state } = useLocation();
  const isRadiusDecreaseRequired = useMediaQuery("(max-width:1240px)");
  const isRadiusDecreaseRequired2 = useMediaQuery("(max-width:1110px)");
  const isRadiusDecreaseRequired3 = useMediaQuery("(max-width:870px)");
  const isPieChartHeightDecreaseRequired = useMediaQuery("(max-width:600px)");
  const isRadiusDecreaseRequired4 = useMediaQuery("(max-width:600px)");
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const organizationStorageData = useSelector(
    (state) => state.getOrganizationStorageDataReducer
  );
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const currentStoragePlan = organizationStorageData?.currentStoragePlan;
  const totalStorageAvailableInGb = ConvertKiloByteToGigaByte(
    organizationStorageData?.totalAvailableSize
  );
  const totalStorageUsedInGb = ConvertKiloByteToGigaByte(
    organizationStorageData?.usedSize
  );
  const toatlStorageUsedPercentage = (
    (totalStorageUsedInGb * 100) /
    totalStorageAvailableInGb
  )?.toFixed(2);

  const usersStorage =
    (organizationStorageData?.users * 100) /
    organizationStorageData?.totalAvailableSize;
  const emptyStorage =
    100 -
    (organizationStorageData?.usedSize * 100) /
      organizationStorageData?.totalAvailableSize;
  const portfolioAndWorkbookStorage =
    (organizationStorageData?.portfolio * 100) /
    organizationStorageData?.totalAvailableSize;
  const fileAndFolderStorage =
    (organizationStorageData?.fileMangement * 100) /
    organizationStorageData?.totalAvailableSize;
    const assetManagement =
    (organizationStorageData?.asset * 100) /
    organizationStorageData?.totalAvailableSize;
  const serviceTicketStorage =
    (organizationStorageData?.serviceTicket * 100) /
    organizationStorageData?.totalAvailableSize;
  const chatAndDataStorage =
    (organizationStorageData?.chat * 100) /
    organizationStorageData?.totalAvailableSize;

  const storageDataArray = [
    {
      name: " ",
      value: usersStorage > 0 && usersStorage < 1 ? 1 : usersStorage,
    },
    { name: " ", value: emptyStorage },
    {
      name: " ",
      value:
        portfolioAndWorkbookStorage > 0 && portfolioAndWorkbookStorage < 1
          ? 1
          : portfolioAndWorkbookStorage,
    },
    {
      name: " ",
      value:
        fileAndFolderStorage > 0 && fileAndFolderStorage < 1
          ? 1
          : fileAndFolderStorage,
    },
    {
      name: " ",
      value:
        serviceTicketStorage > 0 && serviceTicketStorage < 1
          ? 1
          : serviceTicketStorage,
    },
    {
      name: " ",
      value:
        chatAndDataStorage > 0 && chatAndDataStorage < 1
          ? 1
          : chatAndDataStorage,
    },
    {
      name: " ",
      value:
      assetManagement > 0 && assetManagement < 1
          ? 1
          : assetManagement,
    },
  ];
  const COLORS = [
    "#EDA74C",
    "#EFEFEF",
    "#6890EE",
    "#87D863",
    "#ECDC47",
    "#E1A4A4",
    "#CC6CE7"
  ];

  const storageUsageBreakUpArray = [
    {
      name: "Free Storage",
      color: "#EFEFEF",
      borderColor: "#D1D1D1",
      usedStorage: (totalStorageAvailableInGb - totalStorageUsedInGb).toFixed(
        2
      ),
      usedPercentage: (100 - toatlStorageUsedPercentage).toFixed(2),
    },
    {
      name: "Users & Org",
      color: "#EDA74C",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.users) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.users) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(organizationStorageData?.users)?.toFixed(
              2
            ),
      usedPercentage:
        usersStorage > 0 && usersStorage < 0.01
          ? 0.01
          : usersStorage?.toFixed(2),
    },
    {
      name: "Chat & Data",
      color: "#E1A4A4",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.chat) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.chat) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(organizationStorageData?.chat)?.toFixed(
              2
            ),
      usedPercentage:
        chatAndDataStorage > 0 && chatAndDataStorage < 0.01
          ? 0.01
          : chatAndDataStorage?.toFixed(2),
    },
    {
      name: "Service Ticketing",
      color: "#ECDC47",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.serviceTicket) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.serviceTicket) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.serviceTicket
            )?.toFixed(2),
      usedPercentage:
        serviceTicketStorage > 0 && serviceTicketStorage < 0.01
          ? 0.01
          : serviceTicketStorage?.toFixed(2),
    },
    {
      name: "Files & Folders",
      color: "#87D863",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.fileMangement) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.fileMangement) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.fileMangement
            )?.toFixed(2),
      usedPercentage:
        fileAndFolderStorage > 0 && fileAndFolderStorage < 0.01
          ? 0.01
          : fileAndFolderStorage?.toFixed(2),
    },
    {
      name: "Portfolio & Workbook",
      color: "#6890EE",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.portfolio) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.portfolio) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.portfolio
            )?.toFixed(2),
      usedPercentage:
        portfolioAndWorkbookStorage > 0 && portfolioAndWorkbookStorage < 0.01
          ? 0.01
          : portfolioAndWorkbookStorage?.toFixed(2),
    },
    {
      name: "Asset Management",
      color: "#CC6CE7",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.asset) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.asset) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.asset
            )?.toFixed(2),
      usedPercentage:
      assetManagement > 0 && assetManagement < 0.01
          ? 0.01
          : assetManagement?.toFixed(2),
    },
  ];

  const [showUpgradePlanPopup, setShowUpgradePlanPopup] = useState(
    state?.showUpgradePlan || false
  );
  const [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    getCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCardDetails = () => {
    const cardDetailsResponse = getCreditCardDetails(token);
    cardDetailsResponse.then((res) => {
      if (res?.data?.status) {
        setCardDetails(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="org-storage-dashboard-main">
        <div className="org-storage-dashboard-header">
          <Headers />
        </div>

        <div className="org-storage-dashboard-navigation">
          <Navigation />
        </div>

        <div className="org-storage-dashboard-wrapper">
          <div className="org-storage-dashboard-wrapper-content">
            <aside>
              <header>
                <label>Data Usage Chart</label>
                {loggedInUserDetails?.storage_subscription === "manager" && (
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(92, 161, 39)",
                    }}
                    onClick={() => setShowUpgradePlanPopup(true)}
                  >
                    UPGRADE PLAN
                  </Button>
                )}
              </header>
              <ResponsiveContainer
                width="100%"
                height={isPieChartHeightDecreaseRequired ? 320 : 460}
              >
                <PieChart>
                  <Pie
                    data={storageDataArray}
                    innerRadius={
                      isRadiusDecreaseRequired4
                        ? 80
                        : isRadiusDecreaseRequired3
                        ? 130
                        : isRadiusDecreaseRequired2
                        ? 100
                        : isRadiusDecreaseRequired
                        ? 115
                        : 130
                    }
                    dataKey="value"
                  >
                    {storageDataArray.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className="org-storage-dashboard-pie-chat-ceter-label">
                <label>{toatlStorageUsedPercentage}%</label>
                <label>DATA USED</label>
              </div>
            </aside>
            <section>
              <div className="org-storage-dashboard-section-header-content">
                <div className="org-storage-dashboard-section-header-left-side">
                  <header>
                    <label>Current Plan</label>
                  </header>
                  <span>ENTERPRISE STARTER</span>
                  <div className="org-storage-dashboard-section-header-left-side-body">
                    <label>{totalStorageAvailableInGb} GB</label>
                    <p>DATA AVAILABLE FOR FREE</p>
                  </div>
                </div>
                <div className="org-storage-dashboard-section-header-right-side">
                  <header>
                    <label>Data Usage</label>
                  </header>
                  <span>{toatlStorageUsedPercentage}% DATA USED</span>
                  <div className="org-storage-dashboard-section-header-right-side-body">
                    <div>
                      <label>{totalStorageUsedInGb?.toFixed(2)} GB</label>
                      <p>USED</p>
                    </div>
                    <div>
                      <label>
                        {(
                          totalStorageAvailableInGb - totalStorageUsedInGb
                        )?.toFixed(2)}{" "}
                        GB
                      </label>
                      <p>AVAILABLE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="org-storage-dashboard-section-body-content">
                <header>
                  <label>Usage Breakup</label>
                </header>
                <div className="org-storage-dashboard-section-body-content-cards-container">
                  {storageUsageBreakUpArray.map((item) => {
                    return (
                      <div className="org-storage-dashboard-section-body-content-cards">
                        <div
                          className="org-storage-dashboard-cards-color"
                          style={{
                            backgroundColor: item.color,
                            borderColor: item.borderColor
                              ? item.borderColor
                              : item.color,
                          }}
                        />
                        <span>
                          {item.name}: {item.usedStorage} GB /{" "}
                          {item.usedPercentage} %
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showUpgradePlanPopup && (
        <PurchaseStoragePlanPopup
          hide={setShowUpgradePlanPopup}
          token={token}
          totalStorageUsedInGb={totalStorageUsedInGb}
          selectedPlanId={+currentStoragePlan?.planId}
          defaultCardDetails={cardDetails?.filter(
            (item) => item?.default === true
          )}
          setLoader={setLoader}
          resetLoader={resetLoader}
          from={state?.from}
          buttonText={
            state?.from === "renewCloudSubscription" ? "Pay Now" : "Proceed"
          }
        />
      )}
      {loader}
    </>
  );
};

export default OrganizationStorageDashboard;
