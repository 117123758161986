import "./CreateAssetServiceTicket.css";

import React, { useEffect, useRef, useState } from "react";
import {
  addingMultipleFilesST,
  createServiceTicket,
  createAssetServiceTicket,
  createInternalServiceTicket,
} from "Config/API/action";
import {
  getAssetAllFolder,
  getGeneralInfo,
  getNonConnectedOrgs,
  getTicketId,
  listOfAppointOrg,
  listOfServiceTicketPermissionUsers,
  listOfServiceTicketUsers,
} from "Config/API/get";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import AssetIcon from "Media/icons/asset-icon.png";
import Headers from "Components/Header/Headers";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { Link } from "react-router-dom";
import Navigation from "Components/SideNavBar/Navigation";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import blank from "Media/blank-profile-picture.png";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import folderIcon from "Media/icons/Folder-Close.png";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import { servicePageTypeAction } from "Redux/action/render.action";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import videoFile from "Media/icons/videoFile.png";
import { raiseRequestIdAction } from "Redux/action/variable.action";

const CreateAssetServiceTicket = () => {
  const locate = useLocation();
  const { assethas } = locate.state || {};
  const assetha = useSelector((state) => state.assetHashReducer);
  const assethash = assetha && assetha.length > 0 ? assetha : assethas;
  let isConnected = "yes";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isStorageLimitReached = useIsStorageLimitReached();
  const { state } = useLocation();
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  let org_id = useSelector((state) => state.renderGeneralInfo?.org_id);
  let user_id = useSelector((state) => state.renderGeneralInfo?.id);

  const previousData = useSelector(
    (state) => state.selectedOrganizationSTReducer
  );
  const previousUserData = useSelector(
    (state) => state.raiseRequestDataReducer
  );
  const notConnectedAmnius = useSelector(
    (state) => state.notConnectedAmniusReducer
  );
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const assetId = useSelector((state) => state.assetIdReducer);
  
  const [organization, setOrganization] = useState("");
  const [requestor, setRequestor] = useState("");
  const [raise_request_id, setRaise_request_id] = useState("");
  const [folderLink, setFolderLink] = useState("");
  const [folderId, setFolderId] = useState("");
  const [requestorId, setRequestorId] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [folder, setFolder] = useState("");
  const [folderList, setFolderList] = useState("");
  const [orgList, setOrgList] = useState("");
  const [userList, setUserList] = useState("");
  const [requestorList, setRequestorList] = useState("");
  const [users, setUsers] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [severity, setSeverity] = useState("none");
  const [selectedUser, setSelectedUser] = useState("");
  const [requestorOrgPhone, setRequestorOrgPhone] = useState("");
  const [requestorName, setRequestorName] = useState("");
  const [requestorPhone, setRequestorPhone] = useState("");
  const [requestorEmail, setRequestorEmail] = useState("");
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [generalInfo, setGeneralInfo] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [files, setFiles] = useState([]);
  const [desc, setDesc] = useState(ticketData?.description || "");

  if (notConnectedAmnius) {
    isConnected = "no";
  }
  if (org_id?.toString() === "0") {
    org_id = user_id;
  }
  let assetScope;
  let location = "";
  let category_id = "";

 
  let requestorOrgName = "";
  const wrapperRef = useRef(null);
  const userRef = useRef(null);
  const reqRef = useRef(null);
  const folderRef = useRef(null);
  const requestorRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(userRef);
  useOutsideAlerter(reqRef);
  useOutsideAlerter(folderRef);
  useOutsideAlerter(requestorRef);
  useEffect(() => {
    if (state?.ticketType !== "new ticket") {
      setOrganization(previousData[1] ? previousData[1] : "");
      setRequestor(previousUserData[1] ? previousUserData[1] : "");
      setRaise_request_id(previousUserData[2] ? previousUserData[2] : "");
      setFolderLink(previousUserData[5] ? previousUserData[5] : "");
      setFolderId(previousUserData[4] ? previousUserData[4] : "");
      setRequestorId(previousUserData[0] ? previousUserData[0] : "");
      setSelectedOrg(previousData[0] ? previousData[0] : "");
      setFolder(previousUserData[3] ? previousUserData[3] : "");
    }
  }, [state?.ticketType]);

  useEffect(() => {
    if (ticketData && ticketData.description) {
      setDesc(ticketData.description);
    }
  }, [ticketData]);

  useEffect(() => {
    const ticket_id_status = getTicketId(token);
    ticket_id_status.then((res) => {
      if (res?.status) {
        setTicketId(res?.ticketId);
      }
    });
    if (state === "new ticket" || state?.ticketType === "new ticket") {
      setTicketData([]);
    } else {
      setTicketData(previousUserData[6]);
    }
    console.log(ticketData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (ticketId !== "") {
      if (severity !== "none") {
        setLoader();
        var folder_name = folderId;
        const type = "asset";
        let ticket_status;
        if (selectedOrg?.toString() === "") {
          requestorOrgName = organization;
        } else {
          requestorOrgName = "";
        }
        if (folderId?.toString() === "" && folderLink?.toString() === "") {
          folder_name = folder;
        }
        {
          selectedOrg === generalInfo?.id
            ? (assetScope = "internal")
            : (assetScope = "external");
        }
        if (selectedOrg === generalInfo?.id) {
          // If selectedOrg is internal, call the internal service ticket API
          ticket_status = createInternalServiceTicket(
            token,
            ticketId,
            severity,
            assethash,
            folderLink,
            folder_name,
            ticketData?.files ? ticketData?.files : files,
            desc,
            location,
            category_id,
            selectedUser,
            raise_request_id,
            type
          );
        } else {
          // If selectedOrg is external, call the external service ticket API
          ticket_status = createServiceTicket(
            token,
            ticketId,
            selectedOrg,
            selectedUser,
            severity,
            assethash,
            desc,
            ticketData?.files ? ticketData?.files : files,
            folderLink,
            folder_name,
            isConnected,
            requestorId,
            requestorOrgName,
            requestorOrgPhone,
            requestorName,
            requestorPhone,
            requestorEmail,
            raise_request_id,
            type
          );
        }

        ticket_status?.then(async (res) => {
          resetLoader();
          if (res?.status) {
            try {
              // Call createAssetServiceTicket and await its response
              const response = await createAssetServiceTicket(
                token,
                ticketId,
                assetId,
                assetScope
              );
              if (response) {
                toast(res?.message, {
                  type: "success",
                });
                setIsSubmitting(false);
                navigate("/asset-details");
                dispatch(raiseRequestIdAction(null));
              } else {
                setIsSubmitting(false);
                toast("Failed to create asset service ticket", {
                  type: "error",
                });
              }
            } catch (error) {
              setIsSubmitting(false);
              console.error("Error creating asset service ticket:", error);
              toast("Error creating asset service ticket", {
                type: "error",
              });
            }
          } else {
            setIsSubmitting(false);
            toast(res?.message, {
              type: "error",
            });
          }
        });
      } else {
        setIsSubmitting(false);
        toast("Please select severity of ticket", { type: "info" });
      }
    }
  };

  return (
    <>
      <div className="stcreate-parent">
        <div className="stcreate-header">
          <Headers />
        </div>

        <div className="stcreate-navigation">
          <Navigation />
        </div>

        <div className="stcreate-main">
          <form
            className="stcreate-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="stcreate-heading">
              <p>Create A New Ticket</p>
            </div>
            <div className="stcreate-wrapper">
              <div className="stcreate-fields">
                <label htmlFor="ticket-no">
                  Ticket # (Auto Generate)<span>*</span>:
                </label>
                <input
                  type="text"
                  id="ticket-no"
                  value={ticketId}
                  required
                  readOnly
                />
              </div>
              <div className="stcreate-fields">
                <label htmlFor="Asset#">Asset #:</label>
                <input
                  type="text"
                  id="Asset#"
                  value={assethash}
                  readOnly
                  style={{
                    color: "#446FA2",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                />
              </div>
              <div className="stcreate-fields">
                <label htmlFor="organization">
                  Requestor Organization<span>*</span>:
                </label>

                <div ref={wrapperRef}>
                  <input
                    type="text"
                    id="organization"
                    value={organization}
                    // this work for intital clicks
                    onClick={() => {
                      if (notConnectedAmnius) {
                        getloginorg();
                        const organization_list = getNonConnectedOrgs(
                          token,
                          ""
                        );

                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      } else {
                        getloginorg();
                        const organization_list = listOfAppointOrg(token, "");
                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    // this work when user types
                    onChange={(e) => {
                      if (notConnectedAmnius) {
                        getloginorg();
                        const organization_list = getNonConnectedOrgs(
                          token,
                          e.target.value
                        );
                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      } else {
                        getloginorg();
                        const organization_list = listOfAppointOrg(
                          token,
                          e.target.value
                        );
                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                      setOrganization(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      // when someone press backspace after selecting org, it resets the value
                      if (e.keyCode === 8) {
                        setSelectedOrg("");
                        setRequestorOrgPhone("");
                      }
                    }}
                    required
                  />

                  <div className="organization-list">
                    {/*****************search result*********************/}
                    {Array.isArray(orgList) && orgList?.length > 0 ? (
                      <>
                        {/* Render generalInfo first if it's not already in orgList */}
                        {!orgList.some((org) => org.id === generalInfo?.id) &&
                        generalInfo ? (
                          <div className="data-list" key="generalInfo">
                            <div>
                              <img
                                src={
                                  generalInfo?.logo ? generalInfo?.logo : blank
                                }
                                alt="Logo"
                              />
                              <span>
                                {generalInfo?.organization_name
                                  ? generalInfo?.organization_name
                                  : "No Name"}
                              </span>
                            </div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                setSelectedOrg(generalInfo?.id);
                                setOrgList("");
                                setOrganization(generalInfo?.organization_name);
                                setRequestorOrgPhone(
                                  generalInfo?.organization_phone_number
                                );
                                setRequestor("");
                                setFolder("");
                                setRequestorName("");
                                setRequestorEmail("");
                                setRequestorPhone("");
                              }}
                            >
                              Select
                            </Button>
                          </div>
                        ) : null}

                        {/* Render the rest of orgList */}
                        {orgList.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <div>
                                <img
                                  src={curr?.logo ? curr?.logo : blank}
                                  alt="Logo"
                                />
                                <span>
                                  {curr?.organization_name
                                    ? curr?.organization_name
                                    : "No Name"}
                                </span>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  setSelectedOrg(curr?.id);
                                  setOrgList("");
                                  setOrganization(curr?.organization_name);
                                  setRequestorOrgPhone(
                                    curr?.organization_phone_number
                                  );
                                  setRequestor("");
                                  setFolder("");
                                  setRequestorName("");
                                  setRequestorEmail("");
                                  setRequestorPhone("");
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              {notConnectedAmnius ? (
                <div className="stcreate-fields">
                  <label htmlFor="organization-phone">
                    Organization Phone:
                  </label>
                  <input
                    type="number"
                    value={requestorOrgPhone}
                    id="organization-phone"
                    disabled={organization === ""}
                    style={{
                      backgroundColor: organization === "" && "#EFEFEF",
                    }}
                    onChange={(e) => {
                      setRequestorOrgPhone(e.target.value);
                    }}
                  />
                </div>
              ) : null}

              {notConnectedAmnius ? (
                <>
                  <div className="stcreate-fields">
                    <label htmlFor="contact-name">
                      Requestor Name<span>*</span>:
                    </label>
                    <div ref={requestorRef}>
                      <input
                        type="text"
                        value={requestorName}
                        id="contact-name"
                        // disabled={
                        //   notConnectedAmnius
                        //     ? organization === ""
                        //     : selectedOrg === ""
                        // }
                        // style={{
                        //   backgroundColor:
                        //     (notConnectedAmnius
                        //       ? organization === ""
                        //       : selectedOrg === "") && "#EFEFEF",
                        // }}
                        onClick={() => {
                          if (selectedOrg !== "") {
                            const user_list = listOfServiceTicketUsers(
                              token,
                              selectedOrg,
                              ""
                            );
                            user_list.then((res) => {
                              if (res?.status) {
                                setRequestorList(res?.data);
                              } else {
                                toast(res?.message, {
                                  type: "error",
                                });
                              }
                            });
                          } else setRequestorList("");
                        }}
                        onChange={(e) => {
                          setRequestorName(e.target.value);
                          if (selectedOrg !== "") {
                            const user_list = listOfServiceTicketUsers(
                              token,
                              selectedOrg,
                              e.target.value
                            );
                            user_list.then((res) => {
                              if (res?.status) {
                                setRequestorList(res?.data);
                              } else {
                                toast(res?.message, {
                                  type: "error",
                                });
                              }
                            });
                          } else setRequestorList("");
                        }}
                        onKeyUp={(e) => {
                          if (e.keyCode === 8) {
                            setRequestorId("");
                            setRequestorName("");
                            setRequestorPhone("");
                            setRequestorEmail("");
                          }
                        }}
                        required
                      />

                      {/****************  User List ***************/}
                      <div className="organization-list">
                        {requestorList.length?.toString() !== "0"
                          ? !requestorList.message
                            ? requestorList?.map((curr, index) => {
                                return (
                                  <div className="data-list" key={index}>
                                    <div>
                                      <img
                                        src={curr?.image ? curr?.image : blank}
                                        alt="Logo"
                                      />
                                      <span>
                                        {curr?.name ? curr?.name : "No Name"}
                                      </span>
                                    </div>
                                    <Button
                                      type="button"
                                      variant="contained"
                                      onClick={() => {
                                        setRequestorId(curr?.id);
                                        setRequestorList("");
                                        setRequestorName(curr?.name);
                                        setRequestorPhone(curr?.phone);
                                        setRequestorEmail(curr?.email);
                                      }}
                                    >
                                      Select
                                    </Button>
                                  </div>
                                );
                              })
                            : null
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="stcreate-fields">
                    <label htmlFor="contact-phone">
                      Requestor Phone<span>*</span>:
                    </label>
                    <input
                      type="number"
                      value={requestorPhone}
                      disabled={organization === ""}
                      style={{
                        backgroundColor: organization === "" && "#EFEFEF",
                      }}
                      id="contact-phone"
                      onChange={(e) => {
                        setRequestorPhone(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="stcreate-fields">
                    <label htmlFor="contact-email">
                      Requestor Email<span>*</span>:
                    </label>
                    <input
                      type="text"
                      value={requestorEmail}
                      disabled={organization === ""}
                      style={{
                        backgroundColor: organization === "" && "#EFEFEF",
                      }}
                      id="contact-email"
                      name="contact-email"
                      onChange={(e) => {
                        setRequestorEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </>
              ) : (
                <div className="stcreate-fields">
                  <label htmlFor="contact">
                    Requestor Name<span>*</span>:
                  </label>
                  <div ref={reqRef}>
                    <input
                      type="text"
                      id="contact-name"
                      name="contact-name"
                      value={requestor}
                      // disabled={
                      //   notConnectedAmnius
                      //     ? organization === ""
                      //     : selectedOrg === ""
                      // }
                      // style={{
                      //   backgroundColor:
                      //     (notConnectedAmnius
                      //       ? organization === ""
                      //       : selectedOrg === "") && "#EFEFEF",
                      // }}
                      onClick={() => {
                        if (selectedOrg !== "") {
                          const user_list = listOfServiceTicketUsers(
                            token,
                            selectedOrg,
                            ""
                          );
                          user_list.then((res) => {
                            if (res?.status) {
                              setRequestorList(res?.data);
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                            }
                          });
                        }
                      }}
                      onChange={(e) => {
                        setRequestor(e.target.value);
                        if (selectedOrg !== "") {
                        const user_list = listOfServiceTicketUsers(
                          token,
                          selectedOrg,
                          e.target.value
                        );
                        user_list.then((res) => {
                          if (res?.status) {
                            setRequestorList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });}
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setRequestorId("");
                        }
                      }}
                      required
                    />

                    {/****************  User List ***************/}
                    <div className="organization-list">
                      {requestorList.length?.toString() !== "0"
                        ? !requestorList.message
                          ? requestorList?.map((curr, index) => {
                              return (
                                <div className="data-list" key={index}>
                                  <div>
                                    <img
                                      src={curr?.image ? curr?.image : blank}
                                      alt="Logo"
                                    />
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                  </div>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      setRequestorId(curr?.id);
                                      setRequestorList("");
                                      setRequestor(curr?.name);
                                    }}
                                  >
                                    Select
                                  </Button>
                                </div>
                              );
                            })
                          : null
                        : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="stcreate-fields">
                <label htmlFor="assign-tech">
                  Assign Tech<span>*</span>:
                </label>
                <div ref={userRef}>
                  <input
                    readOnly
                    type="text"
                    id="assign-tech"
                    name="assign-tech"
                    value={users}
                    required
                    onClick={() => {
                      const user_list = listOfServiceTicketPermissionUsers(
                        token,
                        ""
                      );
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.emp);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setUsers(e.target.value);
                      const user_list = listOfServiceTicketPermissionUsers(
                        token,
                        e.target.value
                      );
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.emp);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        setSelectedUser("");
                      }
                    }}
                  />

                  {/****************  User List ***************/}
                  <div className="organization-list">
                    {userList.length?.toString() !== "0" ? (
                      !userList.message ? (
                        userList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : blank}
                                  alt="Logo"
                                />
                                <span>
                                  {curr?.username ? curr?.username : "No Name"}
                                </span>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  setSelectedUser(curr?.id);
                                  setUserList("");
                                  setUsers(curr?.username);
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          );
                        })
                      ) : (
                        <div className="content-loading">No Result Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="stcreate-fields">
                <label htmlFor="severity">
                  Select Severity Of Issue<span>*</span>:
                </label>

                <select
                  required
                  id="severity"
                  name="severity"
                  className="severity-wrapper"
                  defaultValue={severity}
                  onChange={(e) => {
                    setSeverity(e.target.value);
                  }}
                >
                  <option className="severity-row" value="none" disabled>
                    -- Select an option --
                  </option>
                  <option className="severity-row" value="urgent">
                    Urgent
                  </option>
                  <option className="severity-row" value="moderate">
                    Moderate
                  </option>
                  <option className="severity-row" value="low">
                    Low
                  </option>
                </select>
              </div>
              <div className="stcreate-fields">
                <label htmlFor="folder">Select Folder:</label>
                <div ref={folderRef}>
                  <input
                    type="search"
                    id="folder"
                    value={folder}
                    readOnly={previousUserData[3]?.length > 0}
                    // disabled={!selectedOrg}
                    // style={{
                    //   backgroundColor:
                    //     ((notConnectedAmnius
                    //       ? organization === ""
                    //       : selectedOrg === "") &&
                    //       "#EFEFEF") ||
                    //     (previousUserData[3]?.length > 0 && "#f1f2f6"),
                    // }}
                    onClick={(e) => {
                      if (previousUserData[3]?.length > 0) return;
                      else {
                        const folder_status = getAssetAllFolder(token, assetId);
                        folder_status.then((res) => {
                          if (res?.status) {
                            setFolderList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                            setFolderList(res?.message);
                          }
                        });
                      }
                    }}
                    onChange={(e) => {
                      setFolder(e.target.value);
                      const folder_status = getAssetAllFolder(token, assetId);
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolderList(res?.data);
                        }
                      });
                    }}
                  />

                  {/****************  Folder List ***************/}
                  {folderList && (
                    <div className="organization-list folder-list">
                      {Array.isArray(folderList) && folderList.length > 0 ? (
                        // Filter for only folder-type items
                        folderList.filter(
                          (curr) => curr?.type?.toString() === "folder"
                        ).length > 0 ? (
                          folderList.map((curr, index) => {
                            if (curr?.type?.toString() === "folder") {
                              return (
                                <div
                                  className="data-list"
                                  onClick={() => {
                                    setFolderLink(curr?.sharePath);
                                    setFolderId(curr?.unique_id);
                                    setFolder(curr?.name);
                                    setFolderList("");
                                  }}
                                  key={index}
                                >
                                  <div>
                                    <div className="organization-img-parent">
                                      <img src={folderIcon} alt="Folder" />
                                      <img
                                        src={AssetIcon}
                                        alt="External"
                                        style={{
                                          position: "absolute",
                                          right: "0",
                                          bottom: "-10px",
                                          width: "17.5px",
                                        }}
                                      />
                                    </div>
                                    <span className="multiple-line-text">
                                      <span>{curr?.name || "No Name"}</span>
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div className="orgListAlert">
                            No folders found for the given Asset
                          </div>
                        )
                      ) : (
                        <div className="orgListAlert">
                          No folders found for the given Asset
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="stcreate-fields">
                <label htmlFor="machine-no">Machine Serial #:</label>
                <input
                  type="text"
                  id="machine-no"
                  value={assethash}
                  readOnly
                  style={{
                    color: "#446FA2",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                />
              </div>

              <div className="stcreate-fields stcreate-file-upload">
                <label htmlFor="file">Upload Files:</label>
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <div className="files-wrappper">
                    {ticketData?.files?.length > 0 ? (
                      ticketData?.files?.map((curr, inx) => {
                        const fileType = curr?.aws_name?.split(".").at(-1);
                        let icon = "";

                        switch (fileType) {
                          case "png":
                          case "jpg":
                          case "jpeg":
                            icon = imageIcon;
                            break;

                          case "pdf":
                            icon = pdfIcon;
                            break;

                          case "docx":
                          case "doc":
                            icon = officeIcon;
                            break;

                          case "xlsx":
                          case "xls":
                          case "csv":
                            icon = excelIcon;
                            break;

                          case "mp4":
                          case "mkv":
                          case "webm":
                            icon = videoFile;
                            break;

                          default:
                            icon = unknownFile;
                        }

                        return (
                          <div key={inx} className="file-upload">
                            <Link
                              className="file-upload-link"
                              to={`/app-file-viewer`}
                              onClick={() => {
                                localStorage.setItem("filePath", curr?.path);
                                localStorage.setItem(
                                  "fileType",
                                  curr?.aws_name?.split(".").at(1)
                                );
                              }}
                            >
                              {icon ? (
                                <img
                                  src={icon}
                                  alt="File Icon"
                                  className="file-upload-image"
                                />
                              ) : (
                                <HelpCenterIcon />
                              )}
                              {curr?.aws_name || "unsupported format"}
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <input
                        type="file"
                        id="file"
                        multiple
                        accept=".png,.jpg,.jpeg,.pdf,.docx,.doc,.xlsx,.xls,.csv,.mp4"
                        style={{ padding: "2px" }}
                        onChange={(e) => {
                          let totalSize = 0;
                          for (let i = 0; i < e.target.files.length; i++) {
                            totalSize = totalSize + e.target.files[i].size;
                          }
                          if (isStorageLimitReached(totalSize)) {
                            return setShowStorageLimitReachedPopup(true);
                          }
                          setLoader();
                          const mult_file = addingMultipleFilesST(
                            token,
                            ticketId,
                            e.target.files,
                            "false"
                          );
                          mult_file.then((res) => {
                            res.json().then((response) => {
                              if (response?.status) {
                                if (response?.message?.includes("limit"))
                                  return toast(response?.message, {
                                    type: "info",
                                  });
                                setFiles(response?.data);
                              } else {
                                toast(response?.message, { type: "info" });
                              }
                            });

                            resetLoader();
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="stcreate-fields">
                <label htmlFor="description">
                  Brief Description<span>*</span>:
                </label>
                <textarea
                  required
                  id="description"
                  rows="5"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  name="description"
                ></textarea>
              </div>

              <div className="stcreate-fields">
                <button type="submit" disabled={isSubmitting}>Create New Ticket</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {loader}
    </>
  );
};

export default CreateAssetServiceTicket;
